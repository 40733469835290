@media only screen and (max-width: 1600px) {
.home-page .casino-list-item[data-v-26bd9c41] {
        width: calc(12% - 10px)
}
.casino-list-item-banner[data-v-26bd9c41] {
        width: 130px;
        position: relative
}
.fancy-market-design .fancy-min-max-box[data-v-26bd9c41] {
        width: 90px
}
.market-6 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 196px)
}
.market-6.fancy-market-design .suspended-row[data-v-26bd9c41]::after,
    .market-6.fancy-market-design .suspended-table[data-v-26bd9c41]::after {
        width: 195px
}
.baccarat-odds-container[data-v-26bd9c41] {
        width: 67%
}
app-pie-chart[data-v-26bd9c41] {
        width: 141px;
        transform: scale(.9)
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right[data-v-26bd9c41] {
        right: 24px !important
}
#chart path[data-v-26bd9c41] {
        stroke: none !important
}
}
@media only screen and (max-width: 1440px) {
.casino-list-item-banner[data-v-26bd9c41] {
        width: 130px;
        position: relative
}
.home-page .casino-list-item[data-v-26bd9c41] {
        width: calc(13% - 10px)
}
.game-market[data-v-26bd9c41] {
        margin-bottom: 3px !important
}
.fancy-market .market-nation-detail .market-nation-name[data-v-26bd9c41] {
        max-width: calc(100% - 5px)
}
.place-bet-box .place-bet-box-body .place-bet-stake[data-v-26bd9c41] {
        margin-left: 14px
}
}
@media only screen and (max-width: 1400px) {
.fancy-market-design .fancy-min-max-box[data-v-26bd9c41] {
        width: 70px
}
.market-6 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 174px)
}
.market-6.fancy-market-design .suspended-row[data-v-26bd9c41]::after,
    .market-6.fancy-market-design .suspended-table[data-v-26bd9c41]::after {
        width: 174px
}
.home-page .casino-list-item[data-v-26bd9c41] {
        width: calc(13.4% - 10px)
}
}
@media only screen and (max-width: 1366px) {
.home-page .casino-list-item[data-v-26bd9c41] {
        width: calc(13.8% - 10px)
}
app-pie-chart[data-v-26bd9c41] {
        width: 141px;
        transform: scale(.75)
}
}
@media only screen and (max-width: 1199px) {
.home-page .center-container .nav.sports-tab[data-v-26bd9c41] {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        white-space: nowrap;
        overflow: auto !important
}
.game-market[data-v-26bd9c41] {
        margin-bottom: 3px !important
}
.fancy-tab[data-v-26bd9c41] {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        background-color: var(--bg-secondary)
}
.fancy-tab a[data-v-26bd9c41] {
        flex: 1 1 auto;
        text-align: center;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        text-transform: uppercase;
        font-weight: 600
}
.casino-list-item-banner[data-v-26bd9c41] {
        width: 100%;
        position: relative
}
.matchbettable .table[data-v-26bd9c41] {
        background-color: transparent !important
}
.matchbettable .table th[data-v-26bd9c41],
    .matchbettable .table td[data-v-26bd9c41] {
        padding: 2px 8px;
        vertical-align: middle;
        border: 1px solid #aaa
}
.matchbettable .table td[data-v-26bd9c41],
    .matchbettable .table tr[data-v-26bd9c41] {
        background-color: transparent !important
}
}
@media only screen and (max-width: 991px) {
app-pie-chart[data-v-26bd9c41] {
        width: 100%;
        transform: scale(1)
}
.login-page .logo-login[data-v-26bd9c41] {
        margin-top: 0
}
.login-page .logo-login img[data-v-26bd9c41] {
        max-height: 65px !important;
        max-width: 100%
}
.Poker_oneday .casino-table-box .casino-table-left-box[data-v-26bd9c41] {
        margin-bottom: 6px
}
.Poker_oneday .casino-table-box .casino-table-left-box[data-v-26bd9c41]:first-child {
        width: 100%
}
.Poker_oneday .casino-table-box .casino-table-left-box[data-v-26bd9c41]:last-child {
        width: 100%
}
.Poker_oneday .casino-nation-detail[data-v-26bd9c41] {
        width: 40% !important
}
.Poker_oneday .casino-table-row .casino-odds-box[data-v-26bd9c41] {
        width: 50% !important
}
.Poker_oneday .casino_odds_row[data-v-26bd9c41] {
        width: 60% !important
}
.Poker_oneday .market-title span[data-v-26bd9c41] {
        text-transform: capitalize
}
.Poker_oneday .casino-odds-box[data-v-26bd9c41] {
        width: 30% !important
}
.card32numbers .casino-odds[data-v-26bd9c41] {
        font-size: 55px;
        height: 48px;
        line-height: 52px
}
}
@media only screen and (max-width: 991px) {
body[data-v-26bd9c41] {
        font-size: 12px
}
p[data-v-26bd9c41] {
        font-size: 12px
}
h1[data-v-26bd9c41],
    .h1[data-v-26bd9c41] {
        font-size: 22px
}
h2[data-v-26bd9c41],
    .h2[data-v-26bd9c41] {
        font-size: 20px
}
h3[data-v-26bd9c41],
    .h3[data-v-26bd9c41] {
        font-size: 18px
}
h4[data-v-26bd9c41],
    .h4[data-v-26bd9c41] {
        font-size: 16px
}
h5[data-v-26bd9c41],
    .h5[data-v-26bd9c41] {
        font-size: 14px
}
h6[data-v-26bd9c41],
    .h6[data-v-26bd9c41] {
        font-size: 12px
}
.detail-page .center-container[data-v-26bd9c41] {
        width: 100%
}
a .game-header[data-v-26bd9c41] {
        font-size: 12px;
        margin: 0;
        width: 100%
}
.market-title[data-v-26bd9c41] {
        font-size: 15px;
        padding: 5px 6px;
        height: 32px
}
.market-nation-detail[data-v-26bd9c41] {
        font-size: 13px
}
.market-odd-box[data-v-26bd9c41] {
        width: 20%;
        padding: 2px 0
}
.market-nation-book .market-book[data-v-26bd9c41] {
        font-size: 12px
}
.fancy-min-max-box[data-v-26bd9c41] {
        width: 20%;
        padding: 0 2px;
        display: none
}
.fancy-min-max[data-v-26bd9c41] {
        font-size: 10px
}
.market-odd-box .market-odd[data-v-26bd9c41] {
        font-size: 15px
}
.market-odd-box .market-volume[data-v-26bd9c41] {
        font-size: 11px
}
.market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 210px)
}
.market-1[data-v-26bd9c41],
    .market-2[data-v-26bd9c41],
    .market-2.width30[data-v-26bd9c41],
    .market-3[data-v-26bd9c41],
    .market-4[data-v-26bd9c41],
    .market-4.width70[data-v-26bd9c41],
    .market-5[data-v-26bd9c41],
    .market-6[data-v-26bd9c41],
    .market-9[data-v-26bd9c41],
    .market-10[data-v-26bd9c41],
    .market-11[data-v-26bd9c41],
    .market-12[data-v-26bd9c41] {
        min-width: 100%;
        margin: 0
}
.market-1 .market-row .market-1-item[data-v-26bd9c41] {
        min-width: 50%
}
.market-1 .market-odd-box[data-v-26bd9c41] {
        width: 30%
}
.market-2 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 40%)
}
.market-2 .suspended-row[data-v-26bd9c41]::after,
    .market-2 .suspended-table[data-v-26bd9c41]::after {
        width: 40%
}
.market-2.width30 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 40%)
}
.market-3 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 60%)
}
.market-4 .market-header .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 48%)
}
.market-3 .market-odd-box[data-v-26bd9c41] {
        width: 15%
}
.market-3 .suspended-row[data-v-26bd9c41]::after,
    .market-3 .suspended-table[data-v-26bd9c41]::after {
        width: 60%
}
.market-4 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 72%)
}
.market-4 .market-odd-box[data-v-26bd9c41] {
        width: 12%
}
.market-4 .suspended-row[data-v-26bd9c41]::after,
    .market-4 .suspended-table[data-v-26bd9c41]::after {
        width: 100%
}
.market-5 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 40%)
}
.casino-page-container .ball-runs[data-v-26bd9c41] {
        height: 19px;
        line-height: 19px;
        width: 19px
}
.scorecard-row-box[data-v-26bd9c41] {
        width: 98%
}
.market-5 .suspended-row[data-v-26bd9c41]::after,
    .market-5 .suspended-table[data-v-26bd9c41]::after {
        width: 40%;
        right: 0
}
.market-6 .row.row10[data-v-26bd9c41] {
        margin-left: 0;
        margin-right: 0
}
.market-6 .row.row10>[class*=col-][data-v-26bd9c41],
    .market-6 .row.row10>[class*=col][data-v-26bd9c41] {
        padding-left: 0;
        padding-right: 1px
}
.market-6 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 40%)
}
.market-6 .market-nation-detail .market-nation-name[data-v-26bd9c41] {
        max-width: calc(100% - 30px)
}
.market-6 .market-nation-detail .dropdown i[data-v-26bd9c41] {
        color: var(--text-table);
        cursor: pointer
}
.market-6 .market-nation-detail .dropdown i[data-v-26bd9c41]:after {
        display: none
}
.market-6 .market-nation-detail .dropdown .fancy-min-max[data-v-26bd9c41] {
        text-align: center
}
.market-6 .suspended-row[data-v-26bd9c41]::after,
    .market-6 .suspended-table[data-v-26bd9c41]::after {
        width: 40%
}
.market-9 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 20%)
}
.market-9 .suspended-row[data-v-26bd9c41]::after,
    .market-9 .suspended-table[data-v-26bd9c41]::after {
        width: 20%
}
.market-10 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 20%)
}
.market-10 .market-nation-detail .market-nation-name[data-v-26bd9c41] {
        max-width: calc(100% - 30px)
}
.market-10 .market-nation-detail .dropdown i[data-v-26bd9c41] {
        color: var(--text-table);
        cursor: pointer
}
.market-header .market-odd-box[data-v-26bd9c41] {
        min-height: 0;
        min-height: initial;
        font-size: 12px;
        text-transform: uppercase
}
.fancy-monile-ddesign .market-header .market-odd-box[data-v-26bd9c41] {
        height: 32px
}
.market-10 .market-nation-detail .dropdown i[data-v-26bd9c41]:after {
        display: none
}
.market-10 .suspended-row[data-v-26bd9c41]::after,
    .market-10 .suspended-table[data-v-26bd9c41]::after {
        width: 20%
}
.market-11 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 40%)
}
.market-11 .suspended-row[data-v-26bd9c41]::after {
        width: 40%
}
.market-12 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 40%)
}
.market-12 .jockey-detail[data-v-26bd9c41] {
        margin: 5px 0;
        width: 100%;
        justify-content: flex-start;
        padding: 0 5px
}
.market-12 .suspended-row[data-v-26bd9c41]::after,
    .market-12 .suspended-table[data-v-26bd9c41]::after {
        width: 100%
}
.casino-list-item[data-v-26bd9c41] {
        width: calc(33.33% - 10px)
}
.casino-list-item.rect[data-v-26bd9c41] {
        width: calc(50% - 10px)
}
.suspended-row[data-v-26bd9c41]::after,
    .suspended-table[data-v-26bd9c41]::after {
        font-weight: 600;
        font-family: roboto condensed, sans-serif;
        font-size: 16px;
        color: #ff3c3c
}
.market-header[data-v-26bd9c41],
    .market-row[data-v-26bd9c41] {
        border-bottom: 1px solid #8f8f8f
}
.market-odd-box[data-v-26bd9c41] {
        border-left: 1px solid #8f8f8f
}
.market-header .market-odd-box.lay[data-v-26bd9c41] {
        border-right: 1px solid #8f8f8f
}
.Market-odd-row-box[data-v-26bd9c41] {
        display: flex;
        width: 40%
}
.market-4 .Market-odd-row-box[data-v-26bd9c41] {
        display: flex;
        width: 72%
}
.Market-odd-row-box .market-odd-box[data-v-26bd9c41] {
        width: 50%
}
.market-11 .suspended-row[data-v-26bd9c41]::after,
    .market-11 .suspended-table[data-v-26bd9c41]::after {
        width: 100%
}
.market-2 .suspended-row[data-v-26bd9c41]::after,
    .market-2 .suspended-table[data-v-26bd9c41]::after {
        width: 100%
}
.poker-20 .casino-nation-name[data-v-26bd9c41] {
        font-weight: 600
}
.poker-20 .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        font-size: 14px !important;
        padding: 0 !important
}
.poker-20 .casino-video-cards[data-v-26bd9c41] {
        width: 160px
}
.casino_list_title[data-v-26bd9c41] {
        line-height: 14px;
        min-height: 21px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 3px
}
.video-box-container[data-v-26bd9c41] {
        max-width: 100%
}
.casino-table-left-box[data-v-26bd9c41],
    .casino-table-right-box[data-v-26bd9c41] {
        width: 100%;
        padding: 0
}
.casino-table-header[data-v-26bd9c41],
    .casino-table-row[data-v-26bd9c41] {
        border-bottom: 1px solid #aaa;
        background-color: #fff
}
.cards32b .casino-table-left-box[data-v-26bd9c41] {
        margin-bottom: 7px
}
.casino-table-header .casino-odds-box[data-v-26bd9c41] {
        text-transform: uppercase;
        height: 25px !important
}
.casino-nation-name p[data-v-26bd9c41] {
        line-height: 15px
}
.cards32a .casino_odds_row .casino-odds-box[data-v-26bd9c41] {
        padding: 3px !important
}
.cards32a .casino-odds-box p[data-v-26bd9c41] {
        line-height: 16px;
        font-weight: 500
}
.card32numbers .casino-odds-box[data-v-26bd9c41] {
        border-color: #aaa !important
}
.casino-video-cards img[data-v-26bd9c41] {
        width: 23px
}
.casino-title-header-mobile[data-v-26bd9c41] {
        background-color: var(--bg-primary);
        color: var(--text-primary);
        box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, .5);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative
}
.game-header[data-v-26bd9c41] {
        font-size: 12px;
        width: 100%;
        margin: 0
}
.casino-header .casino-name[data-v-26bd9c41] {
        font-size: 12px
}
.casino-odds-box[data-v-26bd9c41] {
        border-left: 1px solid #aaa
}
.clock .flip-card[data-v-26bd9c41] {
        height: 20px !important
}
.clock .flip[data-v-26bd9c41] {
        position: relative;
        height: 40px;
        width: 30px
}
.clock .content[data-v-26bd9c41] {
        height: 40px
}
.clock .flip-card-front[data-v-26bd9c41],
    .clock .up[data-v-26bd9c41] {
        width: 30px;
        height: 20px
}
.clock .flip-card-back[data-v-26bd9c41],
    .clock .down[data-v-26bd9c41] {
        width: 30px;
        height: 20px
}
.clock .down>div[data-v-26bd9c41],
    .clock .up>div[data-v-26bd9c41] {
        font-size: 40px;
        font-weight: 800;
        line-height: 40px;
        align-self: center
}
.clock .down>div>div[data-v-26bd9c41] {
        margin-top: -19px
}
.clock .content[data-v-26bd9c41] {
        height: 50px;
        margin-left: 29px
}
.clock .count-no[data-v-26bd9c41]:first-child {
        margin-right: 24px !important
}
.clock .count-no[data-v-26bd9c41]:last-child {
        margin-right: 58px !important
}
.main-container .casino-title-header-mobile .nav-tabs[data-v-26bd9c41] {
        box-shadow: none;
        box-shadow: initial
}
.casino-title-header-mobile .casino-rid[data-v-26bd9c41] {
        font-size: 12px
}
.main-container .casino-title-header-mobile .nav-tabs[data-v-26bd9c41] {
        width: auto
}
.teenpatti2 .teen2other .casino-odds-box[data-v-26bd9c41] {
        width: 49%;
        margin-bottom: 10px
}
.teenpatti2 .casino-table-right-box[data-v-26bd9c41] {
        margin-top: 10px
}
.card-odd-box img[data-v-26bd9c41] {
        height: 45px
}
.dt20 .dt20-odd-box[data-v-26bd9c41] {
        margin-right: 6px
}
.dt20 .dt20dragon[data-v-26bd9c41],
    .dt20 .dt20tiger[data-v-26bd9c41],
    .dt20 .dt20pair[data-v-26bd9c41] {
        width: calc(29% - 7px)
}
.dt20 .dt20tie[data-v-26bd9c41] {
        width: calc(13% - 4px)
}
.dtl20 .casino-table-left-box[data-v-26bd9c41],
    .dtl20 .casino-table-right-box[data-v-26bd9c41] {
        width: 49%
}
.dtl20 .casino-nation-detail[data-v-26bd9c41] {
        width: 60%
}
.dtl20 .casino-odds-box[data-v-26bd9c41] {
        width: 40%
}
.casino-video-title>div[data-v-26bd9c41]:first-child {
        padding: 5px
}
.casino-video-title[data-v-26bd9c41] {
        width: calc(100% - 10px);
        left: 5px;
        top: 5px
}
.casino-video-title .rules-icon[data-v-26bd9c41] {
        top: 0;
        font-size: 24px
}
.casino-video-title .casino-name[data-v-26bd9c41] {
        font-size: 12px;
        line-height: 1
}
.casino-video-title .casino-video-rid[data-v-26bd9c41] {
        font-size: 12px;
        line-height: 1
}
.casino-video-cards h5[data-v-26bd9c41] {
        font-size: 12px;
        line-height: 1
}
.sidebar-box-rules table[data-v-26bd9c41] {
        background-color: transparent
}
.clock[data-v-26bd9c41] {
        right: 5px !important;
        bottom: -12px !important
}
.vcasino.bollywood .flip-card-container[data-v-26bd9c41],
    .vcasino.aaa .flip-card-container[data-v-26bd9c41] {
        height: 20px;
        width: 16px
}
.teenpatti1day .casino-odds-box[data-v-26bd9c41] {
        width: 20%;
        font-size: 14px !important
}
.casino-table .min_max_odds[data-v-26bd9c41] {
        font-size: 13px
}
.teenpattiopen .casino-table-header .casino-nation-detail[data-v-26bd9c41] {
        width: 24%
}
.teenpattiopen .casino-odds-box[data-v-26bd9c41] {
        width: 38%;
        text-align: center;
        padding: 1px !important
}
.teenpattiopen .flip-card[data-v-26bd9c41] {
        margin-right: 10px
}
.teenpattiopen .casino-nation-name[data-v-26bd9c41] {
        font-size: 11px
}
.teenpattiopen .patern-name[data-v-26bd9c41] {
        width: auto;
        margin: 0
}
.teenpattiopen .patern-name .card-icon img[data-v-26bd9c41] {
        width: 10px;
        margin-left: 1px
}
.teenpattiopen .patern-name .card-icon[data-v-26bd9c41] {
        padding: 2px 1px;
        font-size: 12px
}
.flip-clock-wrapper ul[data-v-26bd9c41] {
        width: 24px !important;
        height: 34px !important;
        line-height: 29px !important;
        font-size: 22px !important;
        margin: 2px !important;
        border-radius: 2px !important
}
.flip-clock-wrapper ul li a div div.inn[data-v-26bd9c41] {
        font-size: 24px !important
}
.flip-clock-wrapper ul li[data-v-26bd9c41] {
        line-height: 34px !important
}
.newcasino.queen .casino-video-cards[data-v-26bd9c41] {
        bottom: 0;
        top: auto;
        top: initial;
        transform: none;
        transform: initial;
        -webkit-transform: unset;
        -moz-transform: unset;
        -ms-transform: unset;
        -o-transform: unset
}
.newcasino.queen .flip-card[data-v-26bd9c41] {
        height: 15px;
        width: 12px
}
.vcasino.bollywood .flip-card-container[data-v-26bd9c41],
    .vcasino.aaa .flip-card-container[data-v-26bd9c41] {
        height: 15px;
        width: 12px
}
.queen .casino-table-box[data-v-26bd9c41] {
        padding: 5px
}
.queen .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(50% - 10px)
}
.queen .casino-odd-box-container[data-v-26bd9c41]:nth-child(2n) {
        margin-right: 0
}
.error-404[data-v-26bd9c41] {
        min-height: calc(100vh - 159px)
}
.casino-odds-box-theme[data-v-26bd9c41] {
        border-radius: 0
}
.lucky7a .casino-odds .card-icon img[data-v-26bd9c41] {
        height: 12px
}
.lucky7a .card-odd-box img[data-v-26bd9c41] {
        width: 30px
}
.casino-nation-book[data-v-26bd9c41] {
        font-size: 14px;
        margin-top: 4px
}
.lucky7a .casino-table-full-box .lucky7 img[data-v-26bd9c41] {
        width: 46px;
        margin-top: 16px;
        display: inline-block
}
.casino-last-result-title[data-v-26bd9c41] {
        padding: 4px 10px;
        font-size: 12px;
        margin-top: 0 !important
}
.right-sidebar .sidebar-title[data-v-26bd9c41] {
        background-color: var(--bg-secondary85)
}
.sidebar-box-rules .sidebar-title h4[data-v-26bd9c41] {
        width: 100%;
        font-size: 12px;
        text-align: left
}
.sidebar-box-rules .my-bets[data-v-26bd9c41] {
        padding: 0
}
.sidebar-box-rules .table th[data-v-26bd9c41],
    .sidebar-box-rules .table td[data-v-26bd9c41] {
        padding: 3px 8px;
        font-size: 12px
}
.lucky7a .lucky7low[data-v-26bd9c41],
    .lucky7a .lucky7high[data-v-26bd9c41] {
        width: 40%
}
.lucky7a .lucky7 img[data-v-26bd9c41] {
        height: 50px
}
.lucky7a .casino-video-cards h4[data-v-26bd9c41] {
        display: none
}
.lucky7a .casino-table-left-box[data-v-26bd9c41],
    .lucky7a .casino-table-right-box[data-v-26bd9c41] {
        width: 49%
}
.lucky7cards[data-v-26bd9c41] {
        width: 49%;
        margin-top: 10px
}
.baccarat .baccarat-graph[data-v-26bd9c41] {
        width: 100%;
        margin-bottom: 10px
}
.baccarat-odds-container[data-v-26bd9c41] {
        width: 100%
}
.player-box img[data-v-26bd9c41],
    .banker-box img[data-v-26bd9c41] {
        height: 15px;
        margin-right: 4px
}
.casino-page-container .casino-table-full-box .nav-tabs .nav-item[data-v-26bd9c41] {
        flex: 1 1
}
.casino-war .casino-table-header .casino-nation-detail[data-v-26bd9c41] {
        width: 0;
        padding: 0
}
.casino-war .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        width: 16.66%
}
.casino-war .casino-nation-detail[data-v-26bd9c41] {
        width: 60%
}
.casino-war .casino-odds-box[data-v-26bd9c41] {
        width: 40%
}
.aaa .casino-odd-box-container[data-v-26bd9c41] {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid var(--table-border)
}
.aaa .casino-nation-name[data-v-26bd9c41] {
        width: 60%;
        text-align: left;
        padding-left: 5px
}
.aaa .casino-odds-box[data-v-26bd9c41] {
        width: 20%
}
.aaa .casino-table-left-box[data-v-26bd9c41],
    .aaa .casino-table-center-box[data-v-26bd9c41],
    .aaa .casino-table-right-box[data-v-26bd9c41] {
        padding: 5px 5px 0
}
.bollywood .casino-odd-box-container[data-v-26bd9c41] {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid var(--table-border)
}
.bollywood .casino-nation-name[data-v-26bd9c41] {
        width: 60%;
        text-align: left;
        padding-left: 5px
}
.bollywood .casino-odds-box[data-v-26bd9c41] {
        width: 20%
}
.bollywood .casino-table-left-box.left-odd-box[data-v-26bd9c41] {
        width: 100%
}
.bollywood .casino-table-right-box.right-odd-box[data-v-26bd9c41] {
        width: 100%
}
.casino-war .nav-tabs .nav-link[data-v-26bd9c41] {
        width: 100%
}
.casino-war .nav-tabs .nav-link.active[data-v-26bd9c41]::after {
        background-color: #fff
}
.ab2 .playera-bets[data-v-26bd9c41] {
        width: 100%;
        margin-bottom: 20px
}
.ab2 .playera-bets[data-v-26bd9c41]:last-child {
        margin-bottom: 0
}
.worli .nav .nav-item[data-v-26bd9c41] {
        flex: 1 1 auto;
        margin: 1px
}
.worli .nav .nav-item button[data-v-26bd9c41] {
        width: 100%
}
.worli .nav .nav-item .nav-link[data-v-26bd9c41] {
        border-right: 0
}
.worli-odd-box .worli-odd[data-v-26bd9c41] {
        font-size: 20px
}
.worli-clear-box[data-v-26bd9c41] {
        background-color: var(--bg-table-row);
        padding: 5px;
        font-size: 14px
}
.worli-box-title[data-v-26bd9c41] {
        min-height: 18px
}
.sp .worli-left[data-v-26bd9c41],
    .dp .worli-left[data-v-26bd9c41],
    .chart56 .worli-left[data-v-26bd9c41],
    .chart64 .worli-left[data-v-26bd9c41],
    .colordp .worli-left[data-v-26bd9c41] {
        width: 75%
}
.sp .worli-right[data-v-26bd9c41],
    .dp .worli-right[data-v-26bd9c41],
    .chart56 .worli-right[data-v-26bd9c41],
    .chart64 .worli-right[data-v-26bd9c41],
    .colordp .worli-right[data-v-26bd9c41] {
        width: 25%
}
.threecard-title[data-v-26bd9c41] {
        width: 100%
}
.threecardj-cards[data-v-26bd9c41] {
        width: 100%
}
.race20 .casino-table-box[data-v-26bd9c41] {
        padding: 5px
}
.race20 .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(50% - 5px)
}
.race20 .casino-odd-box-container[data-v-26bd9c41]:nth-child(2n) {
        margin-right: 0
}
.race20 .casino-table-left-box[data-v-26bd9c41] {
        width: 100%
}
.race20 .casino-table-right-box[data-v-26bd9c41] {
        width: 100%
}
.raceto17 .casino-table-box[data-v-26bd9c41] {
        padding: 5px
}
.raceto17 .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(50% - 5px)
}
.raceto17 .casino-odd-box-container[data-v-26bd9c41]:nth-child(2n) {
        margin-right: 0
}
.baccarat29 .casino-table-full-box img[data-v-26bd9c41] {
        height: 60px
}
.baccarat29 .casino-odd-box-container[data-v-26bd9c41] {
        width: 50%
}
.trio .casino-table-box[data-v-26bd9c41] {
        padding: 5px
}
.trio .triocards .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(50% - 3px);
        margin-bottom: 10px
}
.trio .triocards .casino-odd-box-container[data-v-26bd9c41]:nth-child(2n) {
        margin-right: 0
}
.trio .trioodds .casino-odd-box-container[data-v-26bd9c41] {
        width: 33%
}
.trio .trioodds .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(33.33% - 4px);
        margin-bottom: 10px
}
.trio .trioodds .casino-odd-box-container[data-v-26bd9c41]:nth-child(3n) {
        margin-right: 0
}
.trio .casino-table-box.trioodds[data-v-26bd9c41] {
        justify-content: center
}
.ab-cards-container[data-v-26bd9c41] {
        width: 95px
}
.ab-cards-container .flip-card[data-v-26bd9c41] {
        height: 30px !important;
        width: 24px !important
}
.ab-cards-container img[data-v-26bd9c41] {
        height: 30px !important;
        width: 24px !important
}
.ab2 .ab-cards-container img[data-v-26bd9c41] {
        height: 30px !important;
        width: 24px !important
}
.casino-result-modal .casino-result-cards img[data-v-26bd9c41] {
        height: 35px
}
.race-result-box[data-v-26bd9c41] {
        width: 235px
}
.race-result-box .video-winner-text[data-v-26bd9c41] {
        font-size: 16px;
        width: 30px
}
.race-result-box .casino-result-cards .k-image[data-v-26bd9c41] {
        right: -35px
}
.race-result-box .casino-winner-icon[data-v-26bd9c41] {
        right: -70px;
        top: 5px
}
.note-number .casino-odd-box-container-box[data-v-26bd9c41] {
        width: 100%;
        margin-right: 0
}
z .note-number .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(50% - 5px)
}
.note-number .casino-odd-box-container[data-v-26bd9c41]:nth-child(2n) {
        margin-right: 0
}
.note-number .casino-nation-name img[data-v-26bd9c41] {
        height: 24px;
        margin-right: 2px
}
.casino-table-box[data-v-26bd9c41] {
        padding: 0
}
.casino-table[data-v-26bd9c41] {
        margin-top: 0
}
.note-number .casino-table-left-box[data-v-26bd9c41] {
        width: 100%;
        display: flex;
        flex-wrap: wrap
}
.note-number .casino-table-left-box .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(50% - 5px)
}
.note-number .casino-table-right-box[data-v-26bd9c41] {
        width: 100%;
        justify-content: center
}
.sevenupbox .casino-table-left-box[data-v-26bd9c41] {
        margin-bottom: 20px
}
.onecard20 .onecard20oddbox[data-v-26bd9c41] {
        margin-right: 6px
}
.onecard20 .onecard20player[data-v-26bd9c41],
    .onecard20 .onecard20dealer[data-v-26bd9c41],
    .onecard20 .onecard20pair[data-v-26bd9c41] {
        width: calc(29% - 7px)
}
.onecard20 .onecard20tie[data-v-26bd9c41] {
        width: calc(13% - 4px)
}
.trio .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(33.33% - 4px);
        margin-right: 5px
}
.trio .casino-nation-name[data-v-26bd9c41] {
        font-size: 11px
}
.trap .casino-video-cards .v-slider[data-v-26bd9c41] {
        height: 110px
}
.super-over .casino-video-cards img[data-v-26bd9c41] {
        height: 24px
}
.super-over-rule[data-v-26bd9c41] {
        width: 100%
}
.super-over-rule h4[data-v-26bd9c41] {
        background-color: var(--bg-secondary85);
        color: var(--text-secondary);
        padding: 5px
}
.cricket20-left[data-v-26bd9c41],
    .cricket20-right[data-v-26bd9c41] {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0
}
a .cricket20-left[data-v-26bd9c41] {
        margin-top: 30px
}
.cricket20 .score-box[data-v-26bd9c41] {
        margin-top: 0;
        margin-bottom: 60px
}
.cricket20 .blbox[data-v-26bd9c41] {
        bottom: -25px
}
.cricket20videobanner[data-v-26bd9c41] {
        height: 90px;
        padding: 6px;
        width: 70%;
        font-size: 16px
}
.cricket20bannertitle[data-v-26bd9c41] {
        top: -15px;
        padding: 5px;
        border-radius: 10px 10px 0 0;
        font-size: 14px
}
.race20 .casino-last-results .result img[data-v-26bd9c41],
    .cricket20 .casino-last-results .result img[data-v-26bd9c41] {
        height: 25px
}
.place-bet-modal .hfquitbtns .hbtn[data-v-26bd9c41] {
        background-image: linear-gradient(-180deg, #03b37f 0, #06553e 100%);
        border-color: #116f52 !important;
        border-width: 2px !important;
        border-top-width: 5px;
        margin-right: 20px;
        color: #fff
}
.place-bet-modal .hfquitbtns .fbtn[data-v-26bd9c41] {
        background-image: linear-gradient(-180deg, #fc4242 0, #6f0404 100%);
        border-color: #6f0404 !important;
        border-width: 2px !important;
        color: #fff
}
.place-bet-modal .hfquitbtns .btn.selected[data-v-26bd9c41] {
        border-color: var(--text-fancy) !important
}
.kbc .casino-table[data-v-26bd9c41] {
        padding: 5px
}
.kbc .kbc-btns[data-v-26bd9c41] {
        width: auto
}
.vcasino .casino-video[data-v-26bd9c41] {
        padding-bottom: 60px
}
.vcasino .casino-video-cards>div[data-v-26bd9c41] {
        margin-right: 20px
}
.vcasino .casino-video-cards h5[data-v-26bd9c41] {
        font-size: 14px
}
.vcasino .casino-video-cards .virtual-winner[data-v-26bd9c41] {
        height: 50px;
        left: -50px;
        top: -5px
}
.vcasino .casino-video-cards>div:last-child .virtual-winner[data-v-26bd9c41] {
        right: -50px
}
.cricket20ballpopup[data-v-26bd9c41] {
        margin-left: -40px;
        margin-top: -40px
}
.cricket20ballpopup img[data-v-26bd9c41] {
        width: 80px
}
.worli-cards-block[data-v-26bd9c41] {
        width: calc(25% - 9px);
        font-size: var(--font-caption);
        padding: 10px 5px;
        margin-right: 10px !important
}
.worli-cards-block[data-v-26bd9c41]:nth-child(4n) {
        margin-right: 0 !important
}
.lottery-box .lottery-card[data-v-26bd9c41] {
        width: 20%
}
.lottery .double .lottery-place-balls[data-v-26bd9c41],
    .lottery .tripple .lottery-place-balls[data-v-26bd9c41] {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background: var(--bg-sidebar);
        margin-top: 10px
}
.lottery-place-balls img[data-v-26bd9c41] {
        width: 30px;
        margin-right: 10px
}
.lottery-buttons[data-v-26bd9c41] {
        width: 100%;
        padding: 5px
}
.lottery .casino-last-results[data-v-26bd9c41] {
        padding: 5px;
        margin-top: 5px
}
.lottery-result-group[data-v-26bd9c41] {
        width: calc(20% - 5px)
}
.lottery-place-balls[data-v-26bd9c41] {
        padding: 0
}
.lottery .casino-table .nav[data-v-26bd9c41] {
        width: 100%
}
.lottery .casino-table .nav .nav-item[data-v-26bd9c41] {
        flex: auto
}
.teenpatti20 .casino-table-header .casino_odds_row .casino-odds-box.back[data-v-26bd9c41]:last-child {
        display: none
}
.teenpatti20 .casino-table-header .casino_odds_row .casino-odds-box.back[data-v-26bd9c41] {
        width: 100% !important
}
.teenpattitest .casino-odds-box[data-v-26bd9c41] {
        border-color: #aaa
}
.teenpattitest .casino-table-row .casino-odds[data-v-26bd9c41] {
        font-size: 14px
}
.teenpattitest .casino-table-row .casino-nation-name[data-v-26bd9c41] {
        font-size: 14px;
        font-weight: 700
}
.teenpattitest .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        font-size: 14px !important
}
.modal .five-cricket-result .table td[data-v-26bd9c41],
    .modal .five-cricket-result .table th[data-v-26bd9c41] {
        font-size: 12px
}
.modal .five-cricket-result .table th[data-v-26bd9c41]:first-child,
    .modal .five-cricket-result .table th[data-v-26bd9c41]:nth-child(8n),
    .modal .five-cricket-result .table th[data-v-26bd9c41]:nth-child(9n) {
        width: 60px
}
.five-cricket-result .table[data-v-26bd9c41] {
        table-layout: auto;
        table-layout: initial
}
.five-cricket .market-6[data-v-26bd9c41] {
        min-width: 100%
}
.cmeter-low>div[data-v-26bd9c41]:first-child,
    .cmeter-high>div[data-v-26bd9c41]:first-child {
        width: 15%
}
.cmeter-high-cards[data-v-26bd9c41],
    .cmeter-low-cards[data-v-26bd9c41] {
        width: 85%
}
.cmeter .casino-table-left-box[data-v-26bd9c41] {
        margin-bottom: 5px
}
.modal .table td[data-v-26bd9c41],
    .modal .table th[data-v-26bd9c41] {
        font-size: 12px
}
.modal-dialog[data-v-26bd9c41] {
        margin: 0 auto;
        padding: 9px
}
.modal-body[data-v-26bd9c41] {
        max-height: calc(100vh - 44px);
        padding: 0
}
.cricket20 .casino-detail[data-v-26bd9c41] {
        margin-top: 30px
}
.scorecard[data-v-26bd9c41] {
        margin: 0;
        width: 100%
}
.market-9 .suspended-box[data-v-26bd9c41]::after {
        width: 20%;
        left: auto;
        left: initial;
        right: 0
}
.market-9 .suspended-box[data-v-26bd9c41]::before {
        width: 20%;
        left: auto;
        left: initial;
        right: 0
}
.horse-table .bet-table-row[data-v-26bd9c41] {
        padding: 0
}
.horse-table .bet-nation-name[data-v-26bd9c41] {
        width: 100%;
        background: var(--bg-table);
        padding: 5px;
        margin-bottom: 5px
}
.horse-time-detail[data-v-26bd9c41] {
        width: 100%
}
.market-12 .market-nation-detail label img[data-v-26bd9c41] {
        height: 20px
}
.market-12 .market-nation-detail .form-check label>div[data-v-26bd9c41]:nth-child(2) {
        width: calc(100% - 35px)
}
.market-12 .market-nation-detail .market-nation-name[data-v-26bd9c41] {
        font-weight: 700;
        width: 100%;
        font-size: 12px
}
.market-12 .market-book[data-v-26bd9c41] {
        width: 100%
}
.horse-banner .horse-banner-detail span[data-v-26bd9c41] {
        position: absolute;
        right: 10px;
        bottom: 5px;
        font-size: 16px;
        color: #fff
}
.horse-banner[data-v-26bd9c41] {
        width: 100%;
        margin-left: 0;
        margin-right: 0
}
.home-page .casino-list-item[data-v-26bd9c41] {
        width: calc(25% - 10px)
}
.rules-section img[data-v-26bd9c41] {
        max-width: 100%
}
.rules-modal .rules-left-sidebar[data-v-26bd9c41] {
        width: 100%;
        min-height: 0;
        min-height: initial;
        margin-top: 5px
}
.rules-modal .rules-left-sidebar .nav[data-v-26bd9c41] {
        flex-wrap: nowrap;
        overflow: auto
}
.rules-modal .rules-left-sidebar .nav .nav-item[data-v-26bd9c41] {
        width: auto;
        flex: 1 1 auto;
        white-space: nowrap
}
.rules-content[data-v-26bd9c41] {
        width: 100%;
        margin-top: 10px
}
.patern-name[data-v-26bd9c41] {
        width: 100%;
        margin: 0
}
.fantasy-banners[data-v-26bd9c41] {
        margin-top: 5px
}
.dropdown .dropdown-item[data-v-26bd9c41] {
        padding: 6px 12px
}
.login-page[data-v-26bd9c41] {
        justify-content: center
}
.odds-title>div[data-v-26bd9c41] {
        margin-right: 20px;
        width: 12px
}
.odds-title .card-character[data-v-26bd9c41] {
        font-size: 12px
}
.report-page .card[data-v-26bd9c41] {
        border: none
}
.report-page .card-header[data-v-26bd9c41] {
        padding: 6px 8px;
        border-radius: 0
}
.report-page .card-title[data-v-26bd9c41] {
        font-size: 18px
}
.report-page .react-datepicker-wrapper[data-v-26bd9c41] {
        width: 100%;
        min-width: 100%
}
.report-page .btn[data-v-26bd9c41] {
        padding: 2px 30px;
        border-radius: 0;
        height: 31px;
        width: 100%
}
.Verification_box h2[data-v-26bd9c41] {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        align-items: center
}
.report-page .Verification_box .btn-group[data-v-26bd9c41] {
        margin-bottom: 10px;
        display: flex
}
.report-page .Verification_box h2 span[data-v-26bd9c41] {
        font-size: 14px
}
.report-page .Verification_box h2 span[data-v-26bd9c41] {
        font-size: 14px;
        border-radius: 3px;
        padding: 6px
}
.report-page .Verification_box button.btn.btn-primary[data-v-26bd9c41] {
        font-size: 14px;
        padding: 5px;
        font-size: 14px;
        border-radius: 4px
}
.report-page .Verification_box .btn-group>.btn-group:not(:last-child)>.btn[data-v-26bd9c41],
    .report-page .Verification_box .btn-group>.btn[data-v-26bd9c41]:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
}
.report-page .Verification_box .btn-group>.btn-group:not(:first-child)>.btn[data-v-26bd9c41],
    .report-page .Verification_box .btn-group>.btn[data-v-26bd9c41]:nth-child(n+3),
    .report-page .Verification_box .btn-group>:not(.btn-check)+.btn[data-v-26bd9c41] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
}
.report-page .Verification_box .btn-group[data-v-26bd9c41] {
        margin-bottom: 10px;
        display: flex;
        width: 85%;
        margin: 0 auto;
        margin-bottom: 7px
}
.report-page .Verification_box .verify-code[data-v-26bd9c41] {
        font-size: 30px;
        margin-top: 3px
}
.report-page .btn-download[data-v-26bd9c41] {
        height: auto;
        width: 196px;
        padding: 8px 5px
}
.report-page .change_btn_value .form-control[data-v-26bd9c41] {
        border-radius: 1px;
        box-shadow: 0 0 2px #7e7e7e;
        border: none;
        height: 34px
}
.report-form.change_btn_value[data-v-26bd9c41] {
        padding: 0 4px
}
.change_btn_value .row.row10>[class*=col-][data-v-26bd9c41],
    .change_btn_value .row.row10>[class*=col][data-v-26bd9c41] {
        padding-left: 3px;
        padding-right: 3px
}
.change_password_design .form-control[data-v-26bd9c41] {
        border: none;
        border-bottom: 1px solid #000 !important;
        border-radius: 0;
        height: 33px
}
.ball-by-ball .market-6 .blb-box[data-v-26bd9c41] {
        width: 20%
}
.ball-by-ball .market-6 .blb-box .market-odd-box[data-v-26bd9c41] {
        width: 100%
}
.ball-by-ball .market-6 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 56%);
        padding-left: 16px
}
.ball-by-ball .market-6 .fancy-min-max[data-v-26bd9c41] {
        font-size: 10px;
        padding-left: 4px
}
}
@media only screen and (max-width: 1199px) {
.mobile-inplay-design .market-11[data-v-26bd9c41] {
        width: 100%
}
.market-6 .fancy-market .market-nation-detail .market-nation-name[data-v-26bd9c41] {
        max-width: calc(100% - 20px);
        font-weight: 600;
        color: #000
}
.login-form[data-v-26bd9c41] {
        padding: 10px
}
.header-top[data-v-26bd9c41] {
        padding: 5px 12px;
        align-items: center;
        height: auto;
        height: initial
}
.header-top .logo-header img[data-v-26bd9c41] {
        max-height: 50px;
        max-width: 120px
}
.header-rules[data-v-26bd9c41] {
        display: none
}
.logo-header[data-v-26bd9c41] {
        display: flex;
        align-items: center
}
.logo-header i[data-v-26bd9c41] {
        font-size: 20px;
        color: var(--text-primary)
}
.user-balance[data-v-26bd9c41] {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        font-size: 14px
}
.user-balance .dropdown[data-v-26bd9c41] {
        display: inline-block;
        margin-left: 5px
}
.user-balance>div[data-v-26bd9c41]:first-child {
        width: 100%;
        text-align: right;
        margin-bottom: 5px
}
.user-balance .dropdown .dropdown-toggle[data-v-26bd9c41] {
        font-size: 14px
}
.search-box-container[data-v-26bd9c41] {
        width: 100%;
        z-index: 2
}
.search-box[data-v-26bd9c41] {
        height: 36px;
        margin-top: 5px
}
.search-box .btn-search[data-v-26bd9c41] {
        height: 30px;
        width: 30px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        font-size: 17px;
        border-radius: 50%;
        border-radius: 0 30px 30px 0
}
.collapse_sreach a.btn-search[data-v-26bd9c41] {
        border-radius: 50%
}
.search-box .btn-search i[data-v-26bd9c41] {
        font-size: 15px;
        cursor: pointer;
        color: #000;
        margin-left: 0
}
.search-box input[data-v-26bd9c41] {
        width: 200px;
        padding: 0;
        border: 0;
        height: 30px;
        border: 0;
        border-radius: 30px 0 0 30px;
        box-shadow: none;
        transition: width .4s linear;
        padding: 0 10px;
        color: #000;
        font-size: 13px;
        line-height: 17px;
        padding: 12px;
        margin-right: -1px
}
.mobile-header-bottom .sports-tab[data-v-26bd9c41] {
        position: relative
}
.header-top .user-balance span i[data-v-26bd9c41] {
        font-size: 14px
}
.user-details .Exposure_txt[data-v-26bd9c41] {
        font-size: 14px;
        text-decoration: underline
}
.user-balance .dropdown .dropdown-toggle span[data-v-26bd9c41] {
        text-decoration: underline
}
.user-balance .dropdown .dropdown-toggle i[data-v-26bd9c41] {
        font-size: 10px
}
.user-menu-dropdown .dropdown-menu[data-v-26bd9c41] {
        background: #f1f5f8;
        border-radius: 0;
        border: 1px solid rgba(0, 0, 0, .15)
}
.user-menu-dropdown .dropdown-item a[data-v-26bd9c41] {
        color: var(--text-body);
        font-size: 1rem;
        color: #212529
}
.header-top .dropdown .dropdown-item[data-v-26bd9c41] {
        font-size: 13px;
        padding: 10px 12px;
        font-family: roboto condensed, sans-serif
}
.user-menu-dropdown .dropdown-item:last-child a[data-v-26bd9c41] {
        color: #b71e2d;
        font-weight: 600
}
.user-menu-dropdown .dropdown-divider[data-v-26bd9c41] {
        display: none
}
.mobile-header-bottom .menu-tabs[data-v-26bd9c41] {
        box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, .5);
        position: relative
}
.sports-tab .nav-link[data-v-26bd9c41] {
        padding: 5px 10px
}
.home-page .bet-nation-name[data-v-26bd9c41] {
        padding-left: 0
}
.news[data-v-26bd9c41] {
        bottom: 14px;
        font-size: 12px;
        width: 85%;
        display: flex;
        align-items: center
}
.main-container[data-v-26bd9c41] {
        min-height: calc(100vh - 145px);
        margin-top: 0;
        align-content: flex-start
}
.left-sidebar[data-v-26bd9c41] {
        display: none
}
.center-main-container[data-v-26bd9c41] {
        width: 100%;
        padding: 0
}
.center-main-container.detail-page[data-v-26bd9c41] {
        padding: 0
}
.main-container .nav-tabs[data-v-26bd9c41] {
        background-color: var(--bg-primary);
        border-bottom: 0;
        box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, .5);
        position: relative;
        width: 100%
}
.sports-tab .nav-link i[data-v-26bd9c41],
    .casino-tab .nav-link i[data-v-26bd9c41] {
        font-size: 15px;
        margin-bottom: 5px;
        display: block
}
.sports-tab.nav-pills .nav-link[data-v-26bd9c41],
    .casino-tab.nav-pills .nav-link[data-v-26bd9c41] {
        background-color: transparent;
        color: var(--text-secondary);
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase
}
.sports-tab.nav-pills .nav-link.active[data-v-26bd9c41],
    .sports-tab.nav-pills .show>.nav-link[data-v-26bd9c41],
    .casino-tab.nav-pills .nav-link.active[data-v-26bd9c41],
    .casino-tab.nav-pills .show>.nav-link[data-v-26bd9c41] {
        background-color: var(--bg-primary);
        color: var(--text-primary)
}
.center-container .nav.sports-tab[data-v-26bd9c41],
    .center-container .nav.casino-tab[data-v-26bd9c41] {
        position: relative;
        width: 100%;
        margin: 0;
        overflow-x: auto;
        background-color: var(--bg-secondary)
}
.own-casino-page .casino-sub-tab-list[data-v-26bd9c41] {
        padding: 0;
        background-color: var(--bg-secondary)
}
.own-casino-page .casino-sub-tab-list .nav.casino-sub-tab[data-v-26bd9c41] {
        background-color: var(--bg-primary65);
        padding: 5px;
        width: 100%
}
.slot-page .casino-tab-list[data-v-26bd9c41],
    .slot-page .casino-sub-tab-list[data-v-26bd9c41] {
        padding: 0
}
.slot-page .casino-sub-tab-list .casino-sub-tab[data-v-26bd9c41] {
        padding: 3px 5px
}
.slot-page.list-page .casino-sub-tab .nav-link[data-v-26bd9c41] {
        height: auto
}
.bet-table[data-v-26bd9c41] {
        padding: 10px
}
.home-page .bet-table[data-v-26bd9c41] {
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden
}
.bet-table-header[data-v-26bd9c41] {
        display: none
}
.bet-table-row[data-v-26bd9c41] {
        padding-bottom: 5px
}
.home-page .bet-table-row[data-v-26bd9c41] {
        padding: 5px 10px
}
.home-page .bet-table[data-v-26bd9c41] {
        padding: 0
}
.casino_list_title[data-v-26bd9c41] {
        line-height: 1.5 !important;
        min-height: 22px !important;
        font-size: 8px;
        border-radius: 4px !important
}
.bet-nation-name[data-v-26bd9c41] {
        width: 100%
}
.bet-nation-odd[data-v-26bd9c41] {
        width: 33.33%
}
.bet-nation-name .bet-nation-game-name[data-v-26bd9c41] {
        max-width: calc(100% - 120px)
}
.home-page .bet-nation-game-name span[data-v-26bd9c41] {
        width: 100%;
        display: block;
        line-height: normal;
        color: #000;
        margin-bottom: 3px
}
.bet-nation-game-name span[data-v-26bd9c41]:first-child {
        font-weight: 700
}
.bet-nation-game-name span[data-v-26bd9c41]:last-child {
        font-size: 12px
}
.bet-nation-name .game-icons[data-v-26bd9c41] {
        width: 120px
}
.bet-nation-name .game-icons .game-icon[data-v-26bd9c41] {
        width: 20px
}
.bet-nation-name .game-icons .game-icon img[data-v-26bd9c41] {
        height: 10px
}
.bet-nation-name .game-icons .game-icon i[data-v-26bd9c41] {
        font-size: 10px
}
.bet-nation-name .game-icons .game-icon .active[data-v-26bd9c41] {
        height: 12px;
        width: 12px;
        background-color: #97cc00
}
.right-sidebar[data-v-26bd9c41] {
        display: block;
        min-height: auto;
        max-height: inherit;
        width: 100% !important;
        position: relative;
        top: inherit
}
.casino-page .right-sidebar[data-v-26bd9c41] {
        margin-left: 0
}
.right-sidebar .sidebar-title[data-v-26bd9c41] {
        height: auto;
        text-align: center
}
.sidebar-title h4[data-v-26bd9c41] {
        width: 100%
}
.center-main-container.list-page[data-v-26bd9c41] {
        padding: 0
}
.list-page .casino-sub-tab[data-v-26bd9c41] {
        width: 100%
}
.center-container .nav.sports-tab[data-v-26bd9c41],
    .center-container .nav.casino-tab[data-v-26bd9c41],
    .center-container .nav.casino-sub-tab[data-v-26bd9c41] {
        overflow-x: auto;
        width: 100%
}
.home-page .center-container .nav.sports-tab[data-v-26bd9c41] {
        background-color: var(--bg-secondary);
        box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, .5)
}
.casino-sub-tab.nav-pills .nav-link[data-v-26bd9c41] {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase
}
.casino-sub-tab.nav-pills .nav-link[data-v-26bd9c41] {
        background: 0 0;
        color: var(--text-primary);
        margin-right: 0;
        margin-bottom: 0;
        border-right: 0;
        text-decoration: none;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px
}
.slot-page.list-page .casino-tab .nav-link[data-v-26bd9c41] {
        height: auto;
        padding: 12px 16px
}
.own-casino-page .casino-sub-tab .nav-link[data-v-26bd9c41] {
        height: auto
}
.slot-page .casino-tab-list[data-v-26bd9c41] {
        padding: 0
}
.slot-page .casino-tab-list .tabs-arow[data-v-26bd9c41] {
        display: none
}
.slot-page .casino-sub-tab-list .tabs-arow[data-v-26bd9c41] {
        display: none
}
.casino-sub-tab.nav-pills .nav-link.active[data-v-26bd9c41] {
        color: var(--text-primary);
        background: var(--bg-primary);
        border-radius: 12px
}
.place-bet-modal[data-v-26bd9c41] {
        padding: 10px 5px
}
.place-bet-modal .stakeactionminus[data-v-26bd9c41],
    .place-bet-modal .stakeactionplus[data-v-26bd9c41] {
        background: #2c3e50;
        height: 28px;
        width: 28px;
        line-height: 28px;
        padding: 0;
        float: left;
        border-radius: 0;
        color: white;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0
}
.place-bet-modal .stakeinput[data-v-26bd9c41] {
        width: 70px;
        height: 28px;
        float: left;
        background-color: #fff;
        border: 1px solid #646464;
        border-radius: 0
}
.place-bet-modal .btn-primary[data-v-26bd9c41] {
        height: 28px;
        line-height: 28px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
        font-weight: 700;
        border-radius: 0
}
.place-bet-modal .place-bet-buttons[data-v-26bd9c41] {
        padding: 0;
}
.place-bet-buttons .btn-place-bet[data-v-26bd9c41] {
        padding: 4px;
        margin: 1px;
        font-weight: 700
}
.place-bet-modal .place-bet-buttons .btn-place-bet[data-v-26bd9c41] {
        width: 32%;
        background-color: #2c3e50;
        color: white;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0
}
.casino-page .center-container[data-v-26bd9c41] {
        width: 100%
}
.casino-nation-name[data-v-26bd9c41] {
        font-size: 12px
}
.casino-odds[data-v-26bd9c41] {
        font-size: 14px;
        text-align: center;
        line-height: 14px
}
.casino-volume[data-v-26bd9c41] {
        font-size: 12px;
        font-weight: 500
}
.casino-last-results .result[data-v-26bd9c41] {
        width: 30px;
        height: 30px;
        font-weight: 600
}
.teenopenresult .casino-winner-icon[data-v-26bd9c41] {
        right: 20px
}
.page-details[data-v-26bd9c41] {
        height: auto
}
.custom-pagination>div[data-v-26bd9c41]:last-child {
        width: 100%;
        margin-top: 5px
}
.own-casino-page .casino-sub-tab-list .tabs-arow[data-v-26bd9c41] {
        display: none
}
.casino-tab-list .tabs-arow[data-v-26bd9c41] {
        display: none
}
.close-slot-frame[data-v-26bd9c41] {
        display: none
}
.mobile-nation-name[data-v-26bd9c41] {
        font-size: 16px;
        padding: 0 5px;
        font-weight: 700
}
.secure-auth .nav-tabs[data-v-26bd9c41] {
        background: 0 0 !important;
        box-shadow: none !important;
        display: flex !important;
        flex-wrap: wrap !important
}
.secure-auth .nav-tabs .nav-item[data-v-26bd9c41] {
        padding: 0
}
.report-page .secure-auth .login-password[data-v-26bd9c41] {
        flex-wrap: wrap;
        margin-top: 10px
}
.report-page .secure-auth .login-password input[data-v-26bd9c41] {
        width: 100%
}
.report-page .secure-auth .login-password button[data-v-26bd9c41] {
        width: 100%;
        margin: 5px 0 0
}
.ab-title[data-v-26bd9c41] {
        writing-mode: vertical-lr;
        text-orientation: upright
}
.baccarat .baccarat-other-odd-box[data-v-26bd9c41] {
        font-size: 11px
}
.race20 .flip-card[data-v-26bd9c41] {
        height: 20px;
        width: 16px
}
.login-box[data-v-26bd9c41] {
        margin: 0% 0
}
.trap-number-icon[data-v-26bd9c41] {
        margin-top: 10px
}
.report-page .table td[data-v-26bd9c41],
    .report-page .table td[data-v-26bd9c41],
    .report-page .table th[data-v-26bd9c41] {
        padding: 7px;
        font-size: 12px;
        white-space: nowrap
}
.report-page .table thead th[data-v-26bd9c41] {
        background: var(--bg-secondary);
        color: var(--text-secondary)
}
.slot-page .casino-tab-list.show-close-btn .tabs-arow.tabs-arow-left+.casino-tab[data-v-26bd9c41] {
        width: 100%
}
.slot-page .casino-sub-tab-list .tabs-arow.tabs-arow-left+.casino-sub-tab[data-v-26bd9c41] {
        width: 100%
}
.slot-page .tabs-arow.tabs-arow-left+.casino-tab[data-v-26bd9c41] {
        width: 100%
}
.latest-event[data-v-26bd9c41] {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
        margin-bottom: 0;
        white-space: nowrap;
        background-color: var(--bg-primary);
        padding: 0 5px 5px
}
.latest-event-item[data-v-26bd9c41] {
        min-width: 0;
        min-width: initial;
        width: auto;
        max-width: none;
        max-width: initial
}
.latest-event-item a[data-v-26bd9c41] {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        white-space: nowrap;
        align-items: center
}
.latest-event-item a span[data-v-26bd9c41] {
        width: 100%;
        text-align: center
}
}
@media only screen and (max-width: 359px) {
.header-top .logo-header img[data-v-26bd9c41] {
        max-height: 45px;
        max-width: 100px
}
.baccarat .baccarat-other-odd-box[data-v-26bd9c41] {
        font-size: 10px
}
.note-number .casino-nation-name img[data-v-26bd9c41] {
        height: 16px
}
.trio .casino-nation-name[data-v-26bd9c41] {
        font-size: 9px
}
}
@media only screen and (min-width: 768px) and (max-width:1199px) {
.detail-page .center-container[data-v-26bd9c41] {
        width: 100%
}
.game-header[data-v-26bd9c41] {
        margin-top: 8px
}
.card-odd-box img[data-v-26bd9c41] {
        height: 55px
}
.market-odd-box[data-v-26bd9c41] {
        width: 60px
}
.market-6 .market-odd-box[data-v-26bd9c41] {
        width: 15%
}
.fancy-min-max-box[data-v-26bd9c41] {
        width: 60px
}
.market-odd-box .market-odd[data-v-26bd9c41] {
        font-size: 16px
}
.market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 360px)
}
.market-2[data-v-26bd9c41] {
        min-width: calc(50% - 8px)
}
.market-2 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-2 .suspended-row[data-v-26bd9c41]::after,
    .market-2 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.market-2.width30 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-2.width30 .suspended-row[data-v-26bd9c41]::after,
    .market-2.width30 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.market-3 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 240px)
}
.market-3 .suspended-row[data-v-26bd9c41]::after,
    .market-3 .suspended-table[data-v-26bd9c41]::after {
        width: 240px
}
.market-4 .suspended-row[data-v-26bd9c41]::after,
    .market-4 .suspended-table[data-v-26bd9c41]::after {
        width: 100%
}
.market-5 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 240px)
}
.market-5 .suspended-row[data-v-26bd9c41]::after,
    .market-5 .suspended-table[data-v-26bd9c41]::after {
        width: 120px;
        right: 120px
}
.market-6 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 30%)
}
.market-6 .suspended-row[data-v-26bd9c41]::after,
    .market-6 .suspended-table[data-v-26bd9c41]::after {
        width: 180px
}
.market-9 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 60px)
}
.market-9 .suspended-row[data-v-26bd9c41]::after,
    .market-9 .suspended-table[data-v-26bd9c41]::after {
        width: 60px
}
.market-10 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-10 .suspended-row[data-v-26bd9c41]::after,
    .market-10 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.market-11 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-11 .suspended-row[data-v-26bd9c41]::after,
    .market-11 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.market-12 .suspended-row[data-v-26bd9c41]::after,
    .market-12 .suspended-table[data-v-26bd9c41]::after {
        width: 360px
}
.casino-page .center-container[data-v-26bd9c41] {
        width: 100%
}
.suspended-row[data-v-26bd9c41]::after,
    .suspended-table[data-v-26bd9c41]::after {
        font-size: 13px
}
.casino-list-item[data-v-26bd9c41] {
        width: calc(25% - 10px)
}
.casino-list-item.rect[data-v-26bd9c41] {
        width: calc(33.33% - 10px)
}
.kbc .hfquitbtns .btn[data-v-26bd9c41] {
        width: 110px;
        height: 110px
}
.market-9 .suspended-box[data-v-26bd9c41]::after {
        width: 60px;
        left: auto;
        left: initial;
        right: 0
}
.market-9 .suspended-box[data-v-26bd9c41]::before {
        width: 60px;
        left: auto;
        left: initial;
        right: 0
}
.home-page .casino-list-item[data-v-26bd9c41] {
        width: calc(16.66% - 10px)
}
}
@media only screen and (min-width: 1200px) and (max-width:1399px) {
.left-sidebar[data-v-26bd9c41] {
        width: 210px
}
.center-main-container[data-v-26bd9c41] {
        width: calc(100% - 210px)
}
.detail-page .center-container[data-v-26bd9c41] {
        width: calc(100% - 352px)
}
.right-sidebar[data-v-26bd9c41] {
        width: 350px
}
.right-sidebar.sticky[data-v-26bd9c41] {
        width: 350px
}
.card-odd-box img[data-v-26bd9c41] {
        height: 45px
}
.market-odd-box[data-v-26bd9c41] {
        width: 55px
}
.fancy-min-max-box[data-v-26bd9c41] {
        width: 55px
}
.market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 330px)
}
.market-odd-box .market-odd[data-v-26bd9c41] {
        font-size: 16px
}
.market-nation-detail[data-v-26bd9c41] {
        font-size: 13px
}
.market-2 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 110px)
}
.market-2 .suspended-row[data-v-26bd9c41]::after,
    .market-2 .suspended-table[data-v-26bd9c41]::after {
        width: 110px
}
.market-2.width30 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 110px)
}
.market-2.width30 .suspended-row[data-v-26bd9c41]::after,
    .market-2.width30 .suspended-table[data-v-26bd9c41]::after {
        width: 110px
}
.market-3 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 220px)
}
.market-3 .suspended-row[data-v-26bd9c41]::after,
    .market-3 .suspended-table[data-v-26bd9c41]::after {
        width: 220px
}
.market-4 .suspended-row[data-v-26bd9c41]::after,
    .market-4 .suspended-table[data-v-26bd9c41]::after {
        width: 100%
}
.market-5 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 220px)
}
.market-5 .suspended-row[data-v-26bd9c41]::after,
    .market-5 .suspended-table[data-v-26bd9c41]::after {
        width: 110px;
        right: 110px
}
.market-9 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 55px)
}
.market-9 .suspended-row[data-v-26bd9c41]::after,
    .market-9 .suspended-table[data-v-26bd9c41]::after {
        width: 55px
}
.market-10 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 110px)
}
.market-10 .suspended-row[data-v-26bd9c41]::after,
    .market-10 .suspended-table[data-v-26bd9c41]::after {
        width: 110px
}
.market-11 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 110px)
}
.market-11 .suspended-row[data-v-26bd9c41]::after,
    .market-11 .suspended-table[data-v-26bd9c41]::after {
        width: 110px
}
.market-12 .suspended-row[data-v-26bd9c41]::after,
    .market-12 .suspended-table[data-v-26bd9c41]::after {
        width: 330px
}
.casino-page .center-container[data-v-26bd9c41] {
        width: calc(100% - 355px)
}
.suspended-row[data-v-26bd9c41]::after,
    .suspended-table[data-v-26bd9c41]::after {
        font-size: 16px
}
.market-2 .suspended-row[data-v-26bd9c41]::after,
    .market-2 .suspended-table[data-v-26bd9c41]::after {
        width: 110px
}
.slot-iframe[data-v-26bd9c41] {
        left: 215px;
        width: calc(100% - 215px)
}
.casino-list-item[data-v-26bd9c41] {
        width: calc(16.66% - 10px)
}
.dt1day .dtredblack .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        font-size: 13px
}
.kbc .hfquitbtns .btn[data-v-26bd9c41] {
        width: 90px;
        height: 90px
}
.flip-card[data-v-26bd9c41] {
        height: 40px;
        width: 32px
}
.vcasino.bollywood .flip-card-container[data-v-26bd9c41],
    .vcasino.aaa .flip-card-container[data-v-26bd9c41] {
        height: 40px;
        width: 32px
}
.vcasino .casino-video-cards .virtual-winner[data-v-26bd9c41] {
        height: 70px;
        left: -70px;
        top: 5px
}
.vcasino .casino-video-cards>div:last-child .virtual-winner[data-v-26bd9c41] {
        right: -70px
}
.market-9 .suspended-box[data-v-26bd9c41]::after {
        width: 55px;
        left: auto;
        left: initial;
        right: 0
}
.market-9 .suspended-box[data-v-26bd9c41]::before {
        width: 55px;
        left: auto;
        left: initial;
        right: 0
}
.fantasy-iframe[data-v-26bd9c41] {
        left: 215px;
        width: calc(100% - 215px)
}
.teenpatti2024 .casino-nation-detail>div img[data-v-26bd9c41] {
        height: 20px
}
.fancy-min-max[data-v-26bd9c41] {
        font-size: 11px
}
.odds-title>div[data-v-26bd9c41] {
        width: 32px
}
}
@media only screen and (min-width: 1400px) and (max-width:1599px) {
.left-sidebar[data-v-26bd9c41] {
        width: 240px
}
.center-main-container[data-v-26bd9c41] {
        width: calc(100% - 240px)
}
.detail-page .center-container[data-v-26bd9c41] {
        width: calc(100% - 402px)
}
.right-sidebar[data-v-26bd9c41] {
        width: 400px
}
.right-sidebar.sticky[data-v-26bd9c41] {
        width: 400px
}
.card-odd-box img[data-v-26bd9c41] {
        height: 50px
}
.market-odd-box[data-v-26bd9c41] {
        width: 60px
}
.fancy-min-max-box[data-v-26bd9c41] {
        width: 60px
}
.market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 360px)
}
.bookmaker4 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-odd-box .market-odd[data-v-26bd9c41] {
        font-size: 16px
}
.market-2 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-2 .suspended-row[data-v-26bd9c41]::after,
    .market-2 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.market-2.width30 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-2.width30 .suspended-row[data-v-26bd9c41]::after,
    .market-2.width30 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.market-3 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 240px)
}
.market-3 .suspended-row[data-v-26bd9c41]::after,
    .market-3 .suspended-table[data-v-26bd9c41]::after {
        width: 240px
}
.market-4 .suspended-row[data-v-26bd9c41]::after,
    .market-4 .suspended-table[data-v-26bd9c41]::after {
        width: 100%
}
.market-5 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 240px)
}
.market-5 .suspended-row[data-v-26bd9c41]::after,
    .market-5 .suspended-table[data-v-26bd9c41]::after {
        width: 120px;
        right: 120px
}
.market-9 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 60px)
}
.market-9 .suspended-row[data-v-26bd9c41]::after,
    .market-9 .suspended-table[data-v-26bd9c41]::after {
        width: 60px
}
.market-10 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-10 .suspended-row[data-v-26bd9c41]::after,
    .market-10 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.market-11 .market-nation-detail[data-v-26bd9c41] {
        width: calc(100% - 120px)
}
.market-11 .suspended-row[data-v-26bd9c41]::after,
    .market-11 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.market-12 .suspended-row[data-v-26bd9c41]::after,
    .market-12 .suspended-table[data-v-26bd9c41]::after {
        width: 360px
}
.casino-page .center-container[data-v-26bd9c41] {
        width: calc(100% - 405px)
}
.suspended-row[data-v-26bd9c41]::after,
    .suspended-table[data-v-26bd9c41]::after {
        font-size: 15px
}
.market-2 .suspended-row[data-v-26bd9c41]::after,
    .market-2 .suspended-table[data-v-26bd9c41]::after {
        width: 120px
}
.slot-iframe[data-v-26bd9c41] {
        left: 245px;
        width: calc(100% - 245px)
}
.casino-list-item[data-v-26bd9c41] {
        width: calc(16.66% - 10px)
}
.dt1day .dtredblack .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        font-size: 14px
}
.kbc .hfquitbtns .btn[data-v-26bd9c41] {
        width: 110px;
        height: 110px
}
.flip-card[data-v-26bd9c41] {
        height: 45px;
        width: 36px
}
.vcasino.bollywood .flip-card-container[data-v-26bd9c41],
    .vcasino.aaa .flip-card-container[data-v-26bd9c41] {
        height: 45px;
        width: 36px
}
.vcasino .casino-video-cards .virtual-winner[data-v-26bd9c41] {
        height: 90px;
        left: -90px
}
.vcasino .casino-video-cards .virtual-winner[data-v-26bd9c41] {
        right: -90px
}
.market-9 .suspended-box[data-v-26bd9c41]::after {
        width: 60px;
        left: auto;
        left: initial;
        right: 0
}
.market-9 .suspended-box[data-v-26bd9c41]::before {
        width: 60px;
        left: auto;
        left: initial;
        right: 0
}
.fantasy-iframe[data-v-26bd9c41] {
        left: 245px;
        width: calc(100% - 245px)
}
.teenpatti2024 .casino-nation-detail>div img[data-v-26bd9c41] {
        height: 24px
}
.fancy-min-max[data-v-26bd9c41] {
        font-size: 11px
}
.odds-title>div[data-v-26bd9c41] {
        width: 36px
}
}
@media only screen and (min-width: 1200px) {
.user-details[data-v-26bd9c41] {
        margin-top: -10px
}
}
@media only screen and (max-width: 1140px) {
.result-modal .casino-result-round-id[data-v-26bd9c41] {
        font-size: 16px
}
.result-modal .modal-body[data-v-26bd9c41] {
        padding: 10px
}
.Result32cardA .casino-result-cards[data-v-26bd9c41] {
        justify-content: space-between
}
.Result32cardA .casino-result-modal .casino-result-cards img[data-v-26bd9c41] {
        height: 45px
}
.Result32cardA .result-item[data-v-26bd9c41] {
        border-radius: 0;
        margin-bottom: 6px;
        padding: 4px 7px;
        box-shadow: 0 0 2px
}
.Result32cardA .casino-result-modal .casino-result-cards[data-v-26bd9c41] {
        margin-top: 0
}
.Lucky7Result .Result-box[data-v-26bd9c41] {
        background-color: #f1f5f8;
        padding: 10px;
        box-shadow: 0 0 5px #aaa
}
.Lucky7Result .casino-result-modal .casino-result-cards img[data-v-26bd9c41] {
        height: 50px
}
}
@media only screen and (max-width: 1023px) {
.search-box svg.icon[data-v-26bd9c41] {
        width: 15px;
        height: 15px;
        cursor: pointer;
        fill: #000;
        margin-left: 1px
}
.ander_bahar .casino-result-cards .casino-result-cardimg img[data-v-26bd9c41] {
        width: 30px
}
.ander_bahar .result-item .result-title[data-v-26bd9c41] {
        font-size: 16px;
        text-align: center;
        margin-bottom: 5px
}
.cards32a .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        text-transform: uppercase;
        font-size: 14px !important;
        color: #212529
}
.casino-odds[data-v-26bd9c41] {
        color: #212529
}
.casino-title-header-mobile[data-v-26bd9c41] {
        background-color: var(--bg-primary);
        color: var(--text-primary);
        box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, .5);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative
}
.casino-title-header-mobile .casino-rid[data-v-26bd9c41] {
        font-size: 12px
}
.casino-title-header-mobile .casino-rid a[data-v-26bd9c41] {
        text-align: right;
        color: #fff;
        text-decoration: underline
}
.game-header[data-v-26bd9c41] {
        font-size: 12px;
        width: 100%;
        margin: 0
}
.casino-header .casino-name[data-v-26bd9c41] {
        font-size: 12px
}
.casino-odds-box[data-v-26bd9c41] {
        border-left: 1px solid #aaa
}
.casino-detail .suspended-row[data-v-26bd9c41]::after,
    .casino-detail .suspended-table[data-v-26bd9c41]::after {
        font-size: 16px;
        font-weight: 700
}
.mobile-match-tab .nav-item[data-v-26bd9c41] {
        flex: inherit !important
}
.mobile-match-tab .mobiletv-btn[data-v-26bd9c41] {
        position: absolute;
        right: 11px;
        font-size: 18px;
        color: #fff;
        top: 9px
}
.mobile-match-tab .mobiletv-btn svg.icon[data-v-26bd9c41] {
        height: 23px;
        width: 23px;
        fill: var(--text-primary)
}
.load-inner[data-v-26bd9c41] {
        position: absolute
}
.poker-6player .casino-table[data-v-26bd9c41] {
        margin-top: 0
}
.poker-6player .nav-tabs[data-v-26bd9c41] {
        box-shadow: none !important
}
.poker-6player .nav-tabs .nav-item[data-v-26bd9c41] {
        width: 50%
}
.poker-6player .nav-tabs .nav-item .nav-link[data-v-26bd9c41] {
        width: 100%
}
.casino-title-header-mobile .nav-item[data-v-26bd9c41] {
        width: auto !important
}
.poker-6player .casino6poker-item[data-v-26bd9c41] {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        border-radius: 0 !important;
        font-size: 14px !important
}
.poker-6player .casino-nation-name[data-v-26bd9c41] {
        font-size: 14px;
        font-weight: 400
}
.poker-6player .patern-name[data-v-26bd9c41] {
        font-weight: 600;
        width: auto
}
.poker-6player .patern-name .card-icon[data-v-26bd9c41] {
        font-size: 12px;
        font-weight: 500
}
.poker-6player .patern-name img[data-v-26bd9c41] {
        height: 12px
}
.poker-6player .suspended-box[data-v-26bd9c41]::before {
        background-size: 18px 18px
}
.poker-6player .hands-container .player[data-v-26bd9c41] {
        margin-bottom: 0%
}
.poker-6player span.min_max_poker6[data-v-26bd9c41] {
        font-size: 10px;
        float: right;
        width: 100%;
        text-align: right;
        font-weight: 500
}
.poker-6player .patern-name img[data-v-26bd9c41] {
        height: 14px
}
.poker-6player .poker6-odds p[data-v-26bd9c41] {
        font-size: 14px
}
.poker-6player .poker6-odds[data-v-26bd9c41] {
        margin-top: 5px;
        padding-left: 3px;
        margin-bottom: 2px
}
.nav-tabs .nav-link.active[data-v-26bd9c41]::after {
        background-color: #fff
}
.poker-6player .casino-odds-box[data-v-26bd9c41] {
        border: none;
        padding: 0 !important
}
.poker-6player .row.row5>[class*=col-][data-v-26bd9c41],
    .poker-6player .row.row5>[class*=col][data-v-26bd9c41] {
        padding-left: 5px;
        padding-right: 3px
}
.dt20 .casino-table-full-box[data-v-26bd9c41] {
        justify-content: center
}
.dt20 .dt20dragon[data-v-26bd9c41],
    .dt20 .dt20tiger[data-v-26bd9c41] {
        width: calc(43% - 7px);
        margin: 0 5px
}
.dt20 .dt20tie[data-v-26bd9c41] {
        width: calc(13% - 4px);
        margin-right: 0
}
.dt20 .dt20pair[data-v-26bd9c41] {
        width: 97%;
        margin: 0;
        padding: 0;
        border: none
}
.dt20 .casino-odds-dwn[data-v-26bd9c41] {
        line-height: 16px
}
.dt20 .card-odd-box img[data-v-26bd9c41] {
        height: 38px
}
.dt20 .card-odd-box[data-v-26bd9c41] {
        margin-bottom: 5px;
        margin-right: 6px
}
.dt20 .casino-odds-box[data-v-26bd9c41] {
        margin: 3px 0;
        border-radius: 0 !important
}
.dt20 .dt20cards[data-v-26bd9c41] {
        padding: 6px 6px 0
}
.dt1day .dtpair[data-v-26bd9c41] {
        margin-top: 15px
}
.dt1day .dtredblack[data-v-26bd9c41] {
        margin-top: 15px
}
.dt1day .casino-table-header .casino-nation-detail[data-v-26bd9c41] {
        border-top: 1px solid #aaa
}
.dt1day .min-max-dt20 span[data-v-26bd9c41] {
        font-size: 14px;
        font-weight: 500 !important
}
.dt1day .min-max-dt20 b[data-v-26bd9c41] {
        font-weight: 500
}
.dt1day .dtpair .casino-odds-box[data-v-26bd9c41] {
        color: #fff
}
.suspended-box[data-v-26bd9c41]::before {
        background-size: 18px 18px !important
}
.dtl20 .casino-nation-detail[data-v-26bd9c41] {
        padding: 0 4px
}
.min_max_collapse a.minmax-btn[data-v-26bd9c41] {
        font-size: 13px;
        color: #000
}
.dtl20 .casino-table li.nav-item[data-v-26bd9c41] {
        width: 33%
}
.dtl20 .casino-table li.nav-item .nav-link[data-v-26bd9c41] {
        width: 100%
}
.dragontiger-box[data-v-26bd9c41] {
        margin-top: 15px;
        background-color: #f1f5f8;
        padding: 10px;
        box-shadow: 0 0 5px #aaa;
        text-align: center
}
.dtl20 .casino-table-box[data-v-26bd9c41] {
        padding: 0;
        padding: 0 8px;
        margin-top: 8px
}
.dtl20 .casino-table-box .casino-table-body[data-v-26bd9c41] {
        border-top: 1px solid #aaa;
        margin-bottom: 2px
}
.dtl20 .min_max_collapse .range-collpase[data-v-26bd9c41] {
        padding: 6px;
        left: inherit;
        right: 0;
        background-color: #fff;
        box-shadow: 0 0 5px #999;
        color: #242424;
        width: max-content
}
.dtl20 .min_max_collapse .range-collpase .min_max_span[data-v-26bd9c41] {
        width: 100%;
        display: inline-block;
        margin: 0 !important
}
.cards32b .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        height: 31px !important;
        border-bottom: 1px solid #aaa
}
.Baccarat2result .result-item[data-v-26bd9c41] {
        box-shadow: 0 0 2px;
        margin-bottom: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding: 5px 8px
}
.Baccarat2result .result-item .result-title[data-v-26bd9c41] {
        font-size: 16px;
        margin-bottom: 3px;
        font-weight: 600
}
.Baccarat2result .casino-result-cards .casino-result-cardimg img[data-v-26bd9c41] {
        width: 34px;
        height: auto
}
.Baccarat2result .casino-result-cards .casino-result-cardimg .img_transform[data-v-26bd9c41] {
        margin-right: 9px !important
}
.Baccarat2result .casino-result-modal .casino-result-cards[data-v-26bd9c41] {
        margin-top: 0
}
.baccarat .baccarat-other-odd-box[data-v-26bd9c41] {
        display: flex;
        min-height: 40px
}
.casino-war .casino-nation-name[data-v-26bd9c41] {
        display: inline-flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-right: 3px
}
.casino-war span.card-icon img[data-v-26bd9c41] {
        height: 9px
}
.casino-war .casino-table-header .flip-card[data-v-26bd9c41] {
        height: 35px !important
}
.casino-war .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        text-transform: uppercase;
        height: 41px !important
}
.casino-war .casino-table[data-v-26bd9c41] {
        margin-top: 0;
        padding-top: 0
}
.casino-war .tab-content[data-v-26bd9c41] {
        padding: 6px
}
.casino-war .casino-table-header[data-v-26bd9c41],
    .casino-table-row[data-v-26bd9c41] {
        border-bottom: 1px solid #aaa;
        border-left: 1px solid #aaa;
        border-right: 1px solid #aaa
}
.casino-war .min_max_collapse .range-collpase .min_max_span[data-v-26bd9c41] {
        display: flex;
        margin: 0 !important
}
.min_max_collapse .range-collpase[data-v-26bd9c41] {
        padding: 6px
}
.queen .casino-odds-box[data-v-26bd9c41] {
        height: 46px
}
.queen .casino-odd-box-container[data-v-26bd9c41] {
        width: calc(50% - 3px)
}
.queen .casino-odd-box-container[data-v-26bd9c41] {
        margin-right: 4px;
        margin-bottom: 5px
}
.queen .casino_odd_row[data-v-26bd9c41] {
        justify-content: space-between;
        padding: 0
}
.queen .casino-odds-box[data-v-26bd9c41] {
        border-width: 2px !important
}
.queen .casino-odds[data-v-26bd9c41] {
        margin-bottom: 5px
}
.ab2 .ab2-box .casino-odds[data-v-26bd9c41] {
        height: 30px
}
.ab2 .ab2-box .casino-odds-box .casino-odds[data-v-26bd9c41] {
        height: 30px
}
.ab2 .card-odd-box[data-v-26bd9c41] {
        margin-right: 4px
}
.ab2 .player-bet-box[data-v-26bd9c41] {
        height: 60px
}
.ab2 .player-sa-box[data-v-26bd9c41] {
        height: 60px
}
.ab2 .casino-odds[data-v-26bd9c41] {
        line-height: 26px
}
.ab2 .casino-table-full-box .playera-bets .playera-title[data-v-26bd9c41] {
        font-size: 18px
}
.ab2 .casino-table-right-box[data-v-26bd9c41] {
        margin-top: 10px
}
.ab2 .casino-table-box[data-v-26bd9c41] {
        margin-top: 10px !important
}
.ab2 .ab2cards[data-v-26bd9c41] {
        margin-top: 10px !important
}
.ab2 .ab-cards-container[data-v-26bd9c41] {
        width: 210px
}
.ab.ab3 .andar-boxmob[data-v-26bd9c41] {
        border: 1px solid #000;
        width: 100%;
        display: block;
        background-color: #ffa07a
}
.ab.ab3 .andar-boxmob .andar_boxmob_title[data-v-26bd9c41] {
        border-bottom: 1px solid #000;
        padding: 5px;
        font-weight: 600;
        text-align: center
}
.andar-boxmob-slider[data-v-26bd9c41] {
        padding: 5px
}
.ab.ab3 .card-odd-box img[data-v-26bd9c41] {
        height: 68px
}
.ab.ab3 .andar-boxmob-slider[data-v-26bd9c41] {
        padding: 5px 20px
}
.ab.ab3 .card-odd-box[data-v-26bd9c41] {
        text-align: center
}
.ab.ab3 .slick-prev[data-v-26bd9c41] {
        /*  background: url(../images/left-arrow-black.webp) no-repeat; */
        position: absolute;
        top: 40%;
        left: 2px;
        font-size: 0 !important;
        height: 13px;
        width: 13px;
        background-size: 13px;
        border: none;
        transform: translateY(-50%)
}
.ab.ab3 .slick-next[data-v-26bd9c41] {
        /*  background: url(../images/right_arrow_black.webp) no-repeat; */
        position: absolute;
        top: 40%;
        right: 12px;
        font-size: 0 !important;
        height: 13px;
        width: 13px;
        background-size: 13px;
        border: none;
        transform: translateY(-50%)
}
.ab.ab3 .casino-result-cards .slick-prev[data-v-26bd9c41] {
        /*  background: url(../images/left-arrow-whi.webp) no-repeat; */
        position: absolute;
        top: 50%;
        left: -12px;
        font-size: 0 !important;
        height: 10px;
        width: 10px;
        background-size: 10px;
        border: none;
        transform: translateY(-50%)
}
.ab.ab3 .casino-result-cards .slick-next[data-v-26bd9c41] {
        /*   background: url(../images/right_arrow_white.webp) no-repeat; */
        position: absolute;
        top: 50%;
        right: -8px;
        font-size: 0 !important;
        height: 10px;
        width: 10px;
        background-size: 10px;
        border: none;
        transform: translateY(-50%)
}
.ab.ab3 .lightgreen_box[data-v-26bd9c41] {
        border: 1px solid #000;
        width: 100%;
        display: block;
        background-color: #90ee90;
        margin-top: 8px
}
.ab.ab3 .lightgreen_box .andar_boxmob_title[data-v-26bd9c41] {
        border-bottom: 1px solid #000;
        padding: 5px;
        font-weight: 600;
        text-align: center
}
.ab.ab3 .ab-cards-container img[data-v-26bd9c41] {
        height: 30px !important;
        width: auto !important
}
.ab.ab3 .ab-cards-container[data-v-26bd9c41] {
        width: 140px
}
.aaa .casino-table-left-box[data-v-26bd9c41],
    .aaa .casino-table-center-box[data-v-26bd9c41],
    .aaa .casino-table-right-box[data-v-26bd9c41] {
        width: 32%
}
.aaa .casino-odds[data-v-26bd9c41] {
        font-size: 14px
}
.aaa .casino-table-box-margin[data-v-26bd9c41] {
        margin: 10px 5px 0
}
.aaa .casino-tavle-box-background[data-v-26bd9c41] {
        background: #fff
}
.aaa .min_max_collapse[data-v-26bd9c41] {
        padding-right: 0
}
.aaa .card-odd-box img[data-v-26bd9c41] {
        height: 42px
}
.aaa .mobile-akbar .casino-nation-detail[data-v-26bd9c41] {
        width: 60%
}
.aaa .mobile-akbar[data-v-26bd9c41] {
        margin-top: 0
}
.threecrdju .andar-boxmob[data-v-26bd9c41] {
        background: #72bbef !important
}
.threecrdju .lightgreen_box[data-v-26bd9c41] {
        background: #faa9ba !important
}
.threecrdju .andar-boxmob .andar_boxmob_title[data-v-26bd9c41] {
        display: flex;
        justify-content: space-between
}
.threecrdju .lightgreen_box .andar_boxmob_title[data-v-26bd9c41] {
        display: flex;
        justify-content: space-between
}
.baccarat .player-box img[data-v-26bd9c41],
    .banker-box img[data-v-26bd9c41] {
        height: 15px;
        margin-right: 5px;
        margin-top: 5px
}
.baccarat .baccarat-other-odd-box[data-v-26bd9c41] {
        display: flex
}
.baccarat .baccarat-graph summary[data-v-26bd9c41] {
        background: #092844d9;
        color: #fff;
        padding: 5px;
        text-align: start
}
.race20 .casino-table-box[data-v-26bd9c41] {
        padding: 0
}
.race20 .casino-nation-name img[data-v-26bd9c41] {
        height: 35px
}
.race20 .casino-odd-box-container[data-v-26bd9c41] {
        padding-top: 5px
}
.race20 .casino-nation-name[data-v-26bd9c41] {
        font-size: 14px
}
.race20 .right_box_padding[data-v-26bd9c41] {
        margin-top: 8px
}
.race20 .casino-table-box_bg[data-v-26bd9c41] {
        padding-bottom: 10px !important;
        padding-left: 10px;
        padding-right: 10px
}
.race20 .casino-table-left-box[data-v-26bd9c41] {
        padding: 5px 5px 10px
}
.race20 .casino-nation-book[data-v-26bd9c41] {
        font-weight: 700
}
.race20 .casinobox-row[data-v-26bd9c41] {
        padding: 1px
}
.race20 .casino-odds[data-v-26bd9c41] {
        font-size: 16px;
        line-height: 17px
}
.race20 .casino-odds-box[data-v-26bd9c41] {
        border: 2px solid #72bbef;
        height: 48px
}
.race20 .min_max_collapse .range-collpase[data-v-26bd9c41] {
        left: -67%;
        transform: translateX(-50%);
        font-weight: 500;
        background: rgb(51 51 51/84%) none repeat scroll 0% 0%
}
.bollywood .casino-nation-name[data-v-26bd9c41] {
        font-size: 12px
}
.bollywood .casino-nation-book[data-v-26bd9c41] {
        font-size: 12px;
        margin-top: 4px;
        font-weight: 700
}
.bollywood .casino-table .min_max_odds[data-v-26bd9c41] {
        padding: 5px 0
}
.bollywood .casino-nation-detail[data-v-26bd9c41] {
        padding-left: 3px
}
.bollywood .casino-table-left-box[data-v-26bd9c41],
    .bollywood .casino-table-right-box[data-v-26bd9c41] {
        margin-bottom: 10px
}
.bollywood .card-odd-box img[data-v-26bd9c41] {
        height: 40px
}
.bollywood .casino-table-header_border[data-v-26bd9c41] {
        border-top: 1px solid #aaa
}
.bollywood .casino-table-right-box h4[data-v-26bd9c41] {
        position: inherit;
        right: inherit;
        text-align: center !important;
        margin-bottom: 9px
}
.bollywood_minmaxcards[data-v-26bd9c41] {
        width: auto !important
}
.bollywood .casino-odds[data-v-26bd9c41] {
        font-size: 15px;
        font-weight: 400
}
.super-over .game-market[data-v-26bd9c41] {
        margin-bottom: 5px
}
.super-over .game-market a.rules_btn[data-v-26bd9c41] {
        font-size: 14px;
        margin-right: 0
}
.cmeter .casino-table-left-box img[data-v-26bd9c41],
    .cmeter .casino-table-right-box img[data-v-26bd9c41] {
        height: 33px
}
.cmeter .card-odd-box[data-v-26bd9c41] {
        margin-right: 4px
}
.cmeter .casino-table-box .text-info[data-v-26bd9c41] {
        font-size: 24px;
        display: block;
        line-height: 23px;
        margin-bottom: 1px
}
.cmeter-low[data-v-26bd9c41],
    .cmeter-high[data-v-26bd9c41] {
        flex-direction: column
}
.cmeter-low>div[data-v-26bd9c41]:first-child,
    .cmeter-high>div[data-v-26bd9c41]:first-child {
        width: 100%;
        text-align: center
}
.cmeter-video-cards-box img[data-v-26bd9c41] {
        height: 35px;
        margin-right: 2px;
        margin-bottom: 3px
}
.cmeter-high-cards[data-v-26bd9c41],
    .cmeter-low-cards[data-v-26bd9c41] {
        width: 100%;
        justify-content: center
}
.casino_list_head[data-v-26bd9c41] {
        display: flex !important;
        width: 100%;
        padding: 5px 10px;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px
}
.cricket2020resulticon[data-v-26bd9c41] {
        width: 30px !important;
        margin: 2px !important
}
.nav-tabs .nav-item[data-v-26bd9c41] {
        padding: 13px 0;
        flex: auto
}
.matchbettable #matched .back[data-v-26bd9c41] {
        background-color: #a7d8fd !important
}
.matchbettable #matched .lay[data-v-26bd9c41] {
        background-color: #f9c9d4 !important
}
.back[data-v-26bd9c41] {
        background-color: #a7d8fd !important
}
.lay[data-v-26bd9c41] {
        background-color: #f9c9d4 !important
}
.home-page .back[data-v-26bd9c41] {
        background-color: #72bbef !important
}
.home-page .lay[data-v-26bd9c41] {
        background-color: #faa9ba !important
}
.login-form[data-v-26bd9c41] {
        border-radius: 8px;
        box-shadow: none;
        width: 300px;
        padding: 26px 16px 16px
}
.login-form .login-title[data-v-26bd9c41] {
        display: none
}
.login-form form span.icon-from[data-v-26bd9c41] {
        display: none
}
.login-form form .input-group>.form-control[data-v-26bd9c41],
    .login-form form .input-group>.form-select[data-v-26bd9c41] {
        width: 100%;
        border-radius: 4px !important;
        border: none;
        background: 0 0;
        border-radius: 0 !important;
        height: 38px;
        padding-left: 15px;
        border-bottom: 1px solid var(--bg-secondary85) !important;
        box-shadow: none;
        outline: none
}
.login-form .input-group[data-v-26bd9c41] {
        margin-bottom: 24px
}
.login-form .btn-primary[data-v-26bd9c41] {
        border-radius: 0
}
.login-form .btn-primary svg.icon[data-v-26bd9c41] {
        float: none !important;
        margin-left: 10px;
        top: 0
}
.login-form .recaptchaTerms[data-v-26bd9c41] {
        font-size: 10px !important;
        line-height: 18px;
        margin-top: 2px !important
}
.login-form .login-btn-loader svg.icon.f-spin[data-v-26bd9c41] {
        position: inherit;
        display: inline;
        top: 0;
        right: inherit
}
.bet-nation-name .game-icons .game-icon i[data-v-26bd9c41] {
        font-size: 12px
}
.bet-nation-name .game-icons .game-icon.fancy-icon img[data-v-26bd9c41] {
        height: inherit;
        width: 15px;
        margin-top: -2px
}
.bet-nation-name .game-icons .game-icon.bookma-icon img[data-v-26bd9c41] {
        height: auto;
        width: 15px;
        margin-top: -5px
}
.bet-nation-odd .odd-box .bet-odd[data-v-26bd9c41] {
        color: #000
}
.bet-table-body .bet-nation-odd.d-xl-none[data-v-26bd9c41] {
        font-size: 12px;
        color: #000
}
.worli-box-title[data-v-26bd9c41] {
        min-height: 26px;
        border: 1px solid #aaa;
        margin: 0;
        padding-top: 3px
}
.worli-left .worli-odd-box[data-v-26bd9c41],
    .worli-full .worli-odd-box[data-v-26bd9c41] {
        width: calc(20% - 1px);
        margin-right: 1px
}
.worli-box-row[data-v-26bd9c41] {
        margin-bottom: 1px
}
.worli-right .worli-odd-box[data-v-26bd9c41] {
        width: calc(50% - 1px);
        margin-right: 1px
}
.worli .worli-right .worli-odd-box[data-v-26bd9c41] {
        width: calc(25% - 1px)
}
.casino-detail .back[data-v-26bd9c41] {
        background-color: #a7d8fd !important
}
.casino-detail .lay[data-v-26bd9c41] {
        background-color: #f9c9d4 !important
}
.worli .worli-odd-box[data-v-26bd9c41] {
        height: 50px;
        border: 1px solid #aaa
}
.worli .worli-odd-box .worli-odd[data-v-26bd9c41] {
        font-size: 40px
}
.worli .worli-left .worli-odd-box.back[data-v-26bd9c41] {
        margin: 0 !important
}
.worli .worli-box-row[data-v-26bd9c41] {
        margin-bottom: 0
}
.worli .worli-left .worli-odd-box[data-v-26bd9c41],
    .worli-full .worli-odd-box[data-v-26bd9c41] {
        width: calc(20% - 0px)
}
.worli .Worli_odd_box_mobile[data-v-26bd9c41] {
        height: 40px
}
.worli .worli-odd-box.back.Worli_odd_box_mobile[data-v-26bd9c41] {
        margin: 0 !important
}
.worli .worli-right .worli-odd-box[data-v-26bd9c41] {
        width: calc(25% - 0px)
}
.worli-left .worli-odd-box[data-v-26bd9c41],
    .worli-full .worli-odd-box[data-v-26bd9c41] {
        margin-right: 0
}
.worli .worli-full[data-v-26bd9c41] {
        margin-top: 0
}
.worli .Woril_mobile_bottom_button[data-v-26bd9c41] {
        background-color: #efefef;
        padding: 5px 10px;
        margin-top: 8px
}
.worli .Woril_mobile_bottom_button p[data-v-26bd9c41] {
        color: #ffff;
        padding: 5px 10px;
        background: #dc3545;
        width: 50px;
        text-align: center;
        justify-content: end;
        display: inline-block
}
.worli .worli-right .worli_spall[data-v-26bd9c41] {
        width: calc(100% - 0px)
}
.worli .sp .worli-right .worli-odd-box[data-v-26bd9c41],
    .dp .worli-right .worli-odd-box[data-v-26bd9c41],
    .chart56 .worli-right .worli-odd-box[data-v-26bd9c41],
    .chart64 .worli-right .worli-odd-box[data-v-26bd9c41],
    .colordp .worli-right .worli-odd-box[data-v-26bd9c41] {
        height: 100px;
        font-size: 14px;
        font-weight: 700
}
.worli .worlibox[data-v-26bd9c41] {
        margin-top: 0
}
.worli .worli_trio[data-v-26bd9c41] {
        border-radius: 0;
        background: #2b329b;
        padding: 10px 20px
}
.worli .worli_plase_bet[data-v-26bd9c41] {
        width: 80px !important;
        background: #007bff !important
}
.worli .worli-left .worli_abr[data-v-26bd9c41] {
        width: calc(25% - 0px)
}
.worli .worli_abr[data-v-26bd9c41] {
        width: 25%
}
.worli .worli_colordp_all_left[data-v-26bd9c41] {
        width: 62%
}
.worli .worli_colordp_all_right[data-v-26bd9c41] {
        width: 38%
}
.worli .nav-tabs[data-v-26bd9c41] {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        flex-wrap: inherit
}
.bet-nation-name .game-icons .game-icon img.icon_game_s[data-v-26bd9c41] {
        height: 14px
}
.DTLresult .result-item[data-v-26bd9c41] {
        margin-top: 10px;
        box-shadow: 0 0 2px;
        padding: 3px 10px
}
.Open_teenpatti_result .result-item[data-v-26bd9c41] {
        box-shadow: 0 0 2px;
        margin-bottom: 5px;
        padding: 6px 9px
}
.Open_teenpatti_result .result-item .result-title[data-v-26bd9c41] {
        margin-bottom: 0
}
.poker-20 .casino-video-cards img[data-v-26bd9c41] {
        width: 26px
}
.one_day_pokerresult .result-item[data-v-26bd9c41] {
        box-shadow: 0 0 2px;
        margin-bottom: 5px;
        padding: 4px 10px
}
.one_day_pokerresult hr[data-v-26bd9c41] {
        display: none
}
.min-max-dt20 span[data-v-26bd9c41] {
        margin-left: 3px;
        font-family: roboto condensed, sans-serif !important;
        color: #000;
        font-weight: 500
}
.min-max-dt20 b[data-v-26bd9c41] {
        font-weight: 500
}
.min-max-dt20 span span[data-v-26bd9c41] {
        margin: 0
}
.dt20 .casino-odds-dwn[data-v-26bd9c41] {
        line-height: 16px;
        color: #000;
        font-weight: 700
}
.dt20 .casino-odds[data-v-26bd9c41] {
        line-height: 19px
}
.dt20 .dt20odds[data-v-26bd9c41] {
        width: calc(50% - 7px)
}
.dt20 .dt20pair[data-v-26bd9c41] {
        width: 98%
}
.dt20 .casino-table-full-box[data-v-26bd9c41],
    .dt20 .casino-table-left-box[data-v-26bd9c41],
    .dt20 .casino-table-right-box[data-v-26bd9c41] {
        justify-content: center;
        padding: 6px 10px
}
.dt20 .min-max-dt20[data-v-26bd9c41] {
        padding-right: 4px
}
.dt20 .dt20cards[data-v-26bd9c41] {
        padding: 0
}
.suspended-box[data-v-26bd9c41]::before {
        background-size: 13px 13px
}
.baccarat .baccarat-graph summary[data-v-26bd9c41]::marker {
        display: none
}
app-pie-chart[data-v-26bd9c41] {
        width: 100%
}
.modal-header[data-v-26bd9c41] {
        min-height: 41px
}
}
@media only screen and (max-width: 576px) {
.clock .flip-card[data-v-26bd9c41] {
        height: 18px !important
}
.clock .flip[data-v-26bd9c41] {
        position: relative;
        height: 36px;
        width: 30px
}
.clock .content[data-v-26bd9c41] {
        height: 36px
}
.clock .flip-card-front[data-v-26bd9c41],
    .clock .up[data-v-26bd9c41] {
        width: 24px;
        height: 18px
}
.clock[data-v-26bd9c41] {
        right: 0 !important;
        bottom: -12px !important;
        overflow: hidden;
        width: 66px !important;
        height: 68px !important
}
.clock .flip-card-back[data-v-26bd9c41],
    .clock .down[data-v-26bd9c41] {
        width: 24px;
        height: 18px
}
.clock .down>div[data-v-26bd9c41],
    .clock .up>div[data-v-26bd9c41] {
        font-size: 26px;
        font-weight: 700;
        line-height: 36px;
        font-family: Helvetica, sans-serif
}
.clock .down>div>div[data-v-26bd9c41] {
        margin-top: -18px
}
.clock .content[data-v-26bd9c41] {
        height: 50px;
        margin-left: 20px
}
.clock .count-no[data-v-26bd9c41] {
        bottom: -6px
}
.clock .count-no[data-v-26bd9c41]:first-child {
        margin-right: 6px !important
}
.clock .count-no[data-v-26bd9c41]:last-child {
        margin-right: 32px !important
}
.clock .content[data-v-26bd9c41] {
        height: 50px;
        width: 24px !important
}
.cards32a.cards32b .flip-card[data-v-26bd9c41],
    .cards32a .flip-card[data-v-26bd9c41] {
        height: auto
}
.header-top[data-v-26bd9c41] {
        padding: 5px 15px
}
.home-page .bet-table-row[data-v-26bd9c41] {
        padding: 5px 15px
}
.casino_list_head[data-v-26bd9c41] {
        padding: 5px
}
.casino-list[data-v-26bd9c41] {
        padding: 0 10px
}
.super-over .market-4 .market-odd-box[data-v-26bd9c41] {
        color: #000
}
.super-over .casino-video-cards img[data-v-26bd9c41] {
        height: 23px
}
.casino-detail .market-title[data-v-26bd9c41] {
        align-items: center
}
.super-over .scorecard[data-v-26bd9c41] {
        margin-bottom: 4px
}
.casino-last-results[data-v-26bd9c41] {
        margin-top: .25rem
}
.casino-page .center-container[data-v-26bd9c41] {
        padding-bottom: .25rem
}
.casino-last-results .result[data-v-26bd9c41] {
        font-weight: 400
}
.super-over .game-market a.rules_btn[data-v-26bd9c41] {
        font-size: 15px
}
.sidebar-box-rules .sidebar-title.super-over-title h4[data-v-26bd9c41] {
        font-size: 1rem;
        text-align: center
}
.right-sidebar .sidebar-title[data-v-26bd9c41] {
        border-bottom: 1px solid rgba(0, 0, 0, .125)
}
.sidebar-box-rules .table th[data-v-26bd9c41],
    .sidebar-box-rules .table td[data-v-26bd9c41] {
        font-size: 14px;
        color: #000
}
.sidebar-box[data-v-26bd9c41] {
        margin-bottom: 0
}
.race20 .casino-detail .casino-odds-box.back[data-v-26bd9c41],
    .race20 .casino-detail .casino-odds-box.lay[data-v-26bd9c41] {
        background-color: transparent !important
}
.cricket5-rules_table .text-center[data-v-26bd9c41] {
        font-size: 16px;
        margin-bottom: 5px
}
.sidebar-box-rules .sidebar-title h4[data-v-26bd9c41] {
        font-size: 15px;
        font-weight: 600;
        line-height: 21px
}
.sidebar-box-rules .table th[data-v-26bd9c41],
    .sidebar-box-rules .table td[data-v-26bd9c41] {
        border: none
}
.sidebar-box-rules .table thead[data-v-26bd9c41] {
        border-bottom: 1px solid rgba(0, 0, 0, .125)
}
.dt20 .casino-odds-box .casino-odds[data-v-26bd9c41] {
        color: #fff;
        font-weight: 400
}
.dt20 .casino-odds span.card-icon img[data-v-26bd9c41] {
        height: 12px
}
.dt20 .casino-odds span.card-icon[data-v-26bd9c41] {
        margin: 0 7px !important
}
.baccarat .baccarat-other-odd-box span[data-v-26bd9c41] {
        display: flex;
        flex-direction: column
}
.baccarat .baccarat-other-odd-box span span[data-v-26bd9c41] {
        display: inline-block
}
.baccarat .baccarat-other-odd-box[data-v-26bd9c41] {
        padding: 8px 9px
}
.cricket2020 .team-score[data-v-26bd9c41] {
        font-size: 14px
}
.lucky7a .casino-odds[data-v-26bd9c41] {
        font-size: 12px !important;
        margin-bottom: 3px
}
.lucky7a .casino-odds-box .casino-odds[data-v-26bd9c41] {
        font-size: 14px !important;
        color: #fff;
        font-weight: 500
}
.casino-war .min_max_collapse a.minmax-btn[data-v-26bd9c41] {
        font-size: 12px;
        color: var(--bg-secondary)
}
.casino-war .casino-table-row[data-v-26bd9c41]:first-child {
        border-top: 1px solid #aaa
}
.casino-war .casino-nation-name[data-v-26bd9c41] {
        padding-left: 0
}
.casino-war .casino-nation-name img[data-v-26bd9c41] {
        height: 32px;
        margin-left: -4px
}
.casino-war span.card-icon img[data-v-26bd9c41] {
        height: 10px;
        margin-left: 1px
}
.dtl20 .min_max_collapse a.minmax-btn[data-v-26bd9c41] {
        font-size: 12px;
        color: var(--bg-secondary)
}
.casino-video-cards h5[data-v-26bd9c41] {
        font-size: 10px;
        font-weight: 400
}
.teenpatti20 .casino-table-header .casino_odds_row .casino-odds-box.back[data-v-26bd9c41] {
        font-size: 14px !important
}
.poker1rules table.table[data-v-26bd9c41] {
        background-color: #f1f5f8
}
.poker1rules table.table td[data-v-26bd9c41] {
        font-size: 12px !important
}
.poker-20 .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        border-color: #aaa
}
.dt1day .dtpair .casino-odds-box span.casino-odds[data-v-26bd9c41] {
        color: #fff;
        font-weight: 500
}
.dt1day .casino-table-header .casino-odds-box[data-v-26bd9c41] {
        border-top: 1px solid #aaa
}
.bollywood .aaa-odd-box .casino-odds-box[data-v-26bd9c41] {
        padding: 6px 10px !important
}
.bollywood .aaa-odd-box .casino-odds-box .casino-odds[data-v-26bd9c41] {
        font-size: 14px;
        font-weight: 400;
        color: #fff
}
.bollywood .casino-nation-name[data-v-26bd9c41] {
        padding-left: 0
}
.suspended-box[data-v-26bd9c41]::before {
        background-size: 13px 13px !important
}
.bollywood .casino_odds_row .casino-odds-box[data-v-26bd9c41]:first-child {
        border-left: 1px solid #aaa;
        border-right: 1px solid #aaa
}
.bollywood .casino-table-right-box[data-v-26bd9c41] {
        background-color: #f1f5f8
}
.bollywood .min_max_collapse a.minmax-btn[data-v-26bd9c41] {
        color: var(--bg-secondary)
}
.lucky7a .casino-video-cards img[data-v-26bd9c41] {
        height: auto;
        width: 23px
}
.lucky7a .casino-table-full-box .lucky7 img[data-v-26bd9c41] {
        width: 56px
}
.lucky7a .casino-table-left-box[data-v-26bd9c41],
    .lucky7a .casino-table-right-box[data-v-26bd9c41] {
        padding-right: 0
}
.lucky7a .casino-table-left-box .min_max_odds[data-v-26bd9c41],
    .lucky7a .casino-table-right-box .min_max_odds[data-v-26bd9c41] {
        padding-right: 10px
}
.report-page .chnagepassword .form-control[data-v-26bd9c41],
    .report-page .chnagepassword .form-select[data-v-26bd9c41] {
        height: 38px;
        font-size: 14px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid var(--bg-secondary)
}
.user-menu-dropdown .dropdown-menu[data-v-26bd9c41] {
        right: -14px !important
}
.fancy-monile-ddesign .market-nation-detail .market-nation-name span[data-v-26bd9c41] {
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical
}
.daimo_Payment .nav-pills .nav-link[data-v-26bd9c41] {
        margin-bottom: 7px
}
.payment_type_tabs li[data-v-26bd9c41] {
        width: 49%;
        display: inline-flex
}
.daimo_Payment .nav-pills .nav-link[data-v-26bd9c41] {
        margin: 0;
        margin-bottom: 7px;
        width: 100%
}
.daimo_Payment[data-v-26bd9c41] {
        padding: 0
}
.payment_type_tabs[data-v-26bd9c41] {
        margin-top: 4px;
        flex-wrap: wrap;
        justify-content: space-between
}
.fancy-monile-ddesign .fancy-market[data-v-26bd9c41] {
        border-bottom: 1px solid #aaa
}
.fancy-monile-ddesign .market-remark[data-v-26bd9c41] {
        width: 100%;
        text-align: right;
        border-top: 1px solid #aaa
}
.news[data-v-26bd9c41] {
        bottom: 11px;
        display: block
}
}